@import 'variables';

.celledSection {
  font-family: $font-default;
  font-size: 1rem;
  background-color: #f5f5f2;
  border-radius: 1.5em;
  padding: 2em 1.5em;
  margin-bottom: 1em;

  @media (min-width: $md-min-width) and (max-width: $md-max-width), (min-width: $xl-min-width) {
    padding: 2em;
  }
}
