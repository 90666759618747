@import '../../styles/variables';

.upgradeScreenContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  min-height: 100vh;
  width: 100%;
  padding-top: 40px;
  background-color: black;
}

.offerTag {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  border-radius: 100px;
  border: 1px solid white;
  background-color: black;

  font-family: $font-title;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  color: white;
  span {
    color: #fffa01;
  }
}

.header {
  margin-top: 12px;
  font-family: $font-title;
  font-size: 48px;
  white-space: nowrap;
  font-style: normal;
  font-weight: 700;
  line-height: 105%;
  letter-spacing: -0.96px;
  text-align: center;
  background: linear-gradient(to bottom, #000000 0%, #ffffff 50%, #ffffff 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.description {
  padding: 12px 16px 12px 16px;
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  text-align: center;
  color: white;
}

.offerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-self: center;
  box-sizing: border-box;
  width: calc(100% - 32px);
  max-width: 1200px;

  padding: 18px 28px 18px 18px;
  margin: 20px 16px 0px 16px;
  border-radius: 15px;

  background-color: white;
  color: black;
}

.collarImageContainer {
  box-sizing: border-box;
  margin-top: 20px;
  width: 100%;
  height: calc((100vw * 3) / 2);
  max-height: calc(100vh / 2);

  background-position: center;
  background-size: cover;
  background-image: url('../../../../public/product_images/black-friday/bf_upgrade_bg.png');
  @media (min-width: $lg-min-width) {
    height: calc((100vw * 3) / 4);
    max-height: calc(100vh / 2);
    background-image: url('../../../../public/product_images/black-friday/bf_upgrade_bg_desktop.png');
  }
}

.offerTitle {
  padding-top: 14px;
  font-family: $font-default;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.145px;
  color: black;
}

.offerPricing {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  align-self: stretch;
  padding-top: 6px;
}

.dealPrice {
  font-family: $font-default;
  font-size: 58px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -1.159px;
  color: black;
}

.regularPrice {
  font-family: $font-default;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.652px;
  text-decoration-line: line-through;
  color: #737373;
}

.offerBulletPoints {
  padding-top: 6px;
  padding-bottom: 16px;
}

.offerBulletPoint {
  align-self: stretch;
  font-family: $font-default;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: #737373;
}

.whatsNewHeader {
  margin-top: 28px;
  align-self: center;
  font-family: $font-title;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 105%;
  letter-spacing: -0.96px;
  text-align: center;
  background: linear-gradient(to bottom, #000000 0%, #ffffff 50%, #ffffff 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 400px) {
    font-size: 40px;
  }
}

.valuePropsContainer {
  margin: 32px 16px 0px 16px;
}

.getSeriesThreeButton {
  position: sticky;
  bottom: 22px;
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: calc(100% - 32px);
  max-width: 1200px;
  padding: 18px 35px;
  border-radius: 100px;

  background-color: rgb(255 250 1);
  color: black;
  border: none;
  text-align: center;
  font-family: $font-default;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.18px;
  cursor: pointer;
}

.disclaimer {
  margin: 12px 16px 16px 16px;
  text-align: center;
  font-family: $font-default;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: #bebebc;
}
