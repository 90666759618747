@import '../../styles/variables';

.applePayButton {
  font-family: $font-default;
  font-size: 1rem;

  svg {
    width: 1.25em;
    height: 1.25em;
    margin-right: 0.25em;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.loading {
    .content {
      display: none;
    }

    &::before {
      background-image: url('../../assets/images/spinner_loader.gif');
      content: '';
      width: 16px;
      height: 16px;
      background-size: 16px;
      display: inline-block;
    }
  }
}
