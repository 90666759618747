@import '../../../../styles/variables';

.main {
  font-family: $font-default;
}

.cartItemsContainer {
  composes: smFullWidth from '../../../../styles/container.module.scss';
}

.summaryContainer {
  composes: smFullWidth from '../../../../styles/container.module.scss';
}

.actionContainer {
  composes: container from '../../../../styles/container.module.scss';
}

.cartItems {
  font-size: 1rem;
  border-radius: 1.5em 1.5em 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.1em;
  gap: 0.1em;
  box-sizing: border-box;

  @media (max-width: $sm-max-width) {
    border-radius: 0;
  }
}

.cartSummary {
  composes: celledSection from '../../../../styles/celledSection.module.scss';
  font-size: 1rem;
  border-radius: 0 0 1.5em 1.5em;

  @media (max-width: $sm-max-width) {
    border-radius: 0;
  }

  .row {
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
  }
}

.cartTotal {
  margin-top: 1em;
  font-size: 1.25rem;
  font-weight: 700;
}
