@import '../../styles/variables';

.container {
  font-family: $font-default;
  margin: 0 0 1em;
  position: relative;

  [class*='__control'],
  [class*='__value-container'] {
    height: 4rem;
    line-height: 4rem;
    cursor: pointer;
  }

  [class*='__control'] {
    border-radius: 2em;
    caret-color: transparent;
  }

  [class*='__value-container'] {
    padding: 0 0 0 2em;
    display: block;
    position: relative;
    caret-color: transparent;
  }

  [class*='__placeholder'] {
    margin-left: 0;
    margin-right: 0;
    position: static;
    transform: none;
    line-height: 4em;
  }

  [class*='__option'] {
    line-height: 2em;
  }

  [class*='__indicators'] {
    margin-right: 1em;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  [class*='__indicator-separator'] {
    background-color: rgba(0, 0, 0, 0);
  }

  [class*='__value-container'] *:last-child {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  [class*='__option'] {
    cursor: pointer;
  }

  [class*='__menu'] {
    z-index: 99;
  }

  [class*='__menu-portal'] {
    z-index: 99;
  }
}

:global(.cart-quantity-container) .container {
  margin: 0;

  [class*='__control'],
  [class*='__value-container'] {
    height: 32px;
    line-height: 32px;
  }

  [class*='__value-container'] {
    padding: 6px 20px 6px 20px;
  }

  [class*='__placeholder'] {
    line-height: 32px;
  }

  [class*='__indicators'] {
    margin-left: 6px;
  }

  [class*='__option'] {
    line-height: 20px;
  }
}

:global(.supplements-update-address-modal) [class*='__control'],
:global(.supplements-update-address-modal) [class*='__value-container'] {
  height: 55px;
  line-height: 55px;
}

:global(.supplements-update-address-modal) [class*='__placeholder'] {
  line-height: 55px;
}
