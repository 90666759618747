@import '../../../../styles/variables';

.container {
  font-family: $font-default;
  flex: 0 1 0;
  flex-direction: column;
}

.title {
  font-family: $font-title;
  text-transform: uppercase;
  font-size: 5rem;
  font-weight: 700;
  line-height: 1em;
  margin-top: 0;
  margin-bottom: 0.1em;

  @media (max-width: $sm-max-width) {
    font-size: 3rem;
  }
}

.price {
  font-size: 1.5rem;
  font-weight: 500;
}
