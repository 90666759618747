@import 'variables';

.splitContainer {
  composes: container from 'container.module.scss';
  padding-left: 0;
  padding-right: 0;

  font-family: $font-default;
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;

  & > * {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
  }
}

.smStacked {
  composes: splitContainer;

  @media (max-width: $sm-max-width) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
}

.mdStacked {
  composes: splitContainer;

  @media (max-width: $md-max-width) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
}

.stickyLeftColumn {
  @media (min-width: $lg-min-width) {
    align-self: flex-start;
    position: sticky;

    // This is currently set to height of header + top padding. Docs seem to indicate that we should be able to
    // set this to 0 (i.e. this should be relative to its container), but that doesn't seem to work.
    top: 144px;
  }
}
