@import '../../../../styles/variables';

.main {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.row {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-family: $font-default;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.01em;

  &.finalRow {
    font-weight: 700;
    font-size: 24px;
    letter-spacing: -0.02em;
  }
}

.footnote {
  color: #737373;
  font-family: $font-default;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.01em;
  font-weight: 500;
  text-align: right;
  margin-top: -2em;
}
