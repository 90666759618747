@import '../../../../../styles/variables';

.container {
  composes: container from '../../../../../styles/container.module.scss';
  padding: 3em 0;
}

.title {
  font-family: $font-default;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.5em;
  padding-left: 1em;
  padding-right: 1em;
}
