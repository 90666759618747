@import '../../styles/variables';

.main {
  font-family: $font-default;
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-top: 24px;

  .content {
    margin: 0 24px;

    hr {
      margin: 0 -24px;
      height: 8px;
      border: none;
      background-color: $light-gray;
    }

    .give1MonthGet1DollarPerMonthDetails {
      padding-bottom: 40px;

      .give1MonthGet1DollarPerMonthDetailsTitle {
        margin: auto;
        height: 176px;
        max-width: 297px;
        background: url('../../assets/images/backgrounds/referral_green_dog.jpg') no-repeat;
        background-size: cover;
        background-position: right;
        border-radius: 16px;
        padding: 15px 24px;
        text-align: left;

        h3 {
          width: 150px;
          font-size: 24px;
          font-weight: 700;
          color: $bg-light-primary;
          line-height: 120%;
          letter-spacing: -0.01em;
          margin: 0;
          padding: 0;
        }

        p {
          width: 160px;
          margin-top: 4px;
          margin-left: 0;
          padding: 0;
          font-size: 24px;
          font-weight: 600;
          line-height: 120%;
          letter-spacing: -0.01em;
          color: #c4f1cb;
          text-align: left;
        }
      }
      .give1MonthGet1DollarPerMonthDetailsPendingEarn {
        margin-top: 24px;
        text-align: center;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.01em;
      }
    }

    .qrCodeWrapper {
      text-align: center;
      margin: auto;

      canvas {
        border: 1.5px solid #f5f5f2;
        border-radius: 16px;
        padding: 20px;
        display: inline;
      }
    }

    h2 {
      text-align: center;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: -0.02em;
    }

    p {
      text-align: center;
      margin: 0 30px;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: #737373;
    }

    p.footerDescription {
      font-size: 11px;
      margin-left: 0;
      margin-right: 0;
      margin-top: 32px;
    }

    .scanOrShareCode {
      margin-top: 43px;
      margin-bottom: 16px;
    }
  }

  .shareButton {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    background-color: #f5f5f2;
    border-radius: 24px;
    padding: 24px;

    .code {
      flex-direction: column;
      display: flex;
      flex: 1;
      gap: 4px;
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      color: $collar-blue;

      .heading {
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: -0.01em;
        color: #737373;
      }
    }
    .icon {
      display: flex;
      justify-content: center;
      flex-direction: column;

      svg {
        display: block;
      }
    }
  }
}
