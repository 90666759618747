@import '../../../../styles/variables';

.divider {
  border: none;
  border-bottom: 1px solid $gray-100;
  width: 100%;
  margin-top: 1em;
  margin-bottom: 2em;
}

@media (max-width: $sm-min-width) {
  .divider {
    margin-top: 4px;
    margin-bottom: 20px;
  }
}
