@import '../../styles/mixins';
@import '../../styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  max-width: inherit;
  max-height: inherit;
  overflow-y: auto;

  p {
    margin: 0;
  }
}

.headerSection {
  padding: 3em 2.5em;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-family: $font-title;
    font-size: 3rem;
    line-height: 1em;
    margin: 0;
    max-width: 9em;
    font-weight: 700;
    margin-bottom: 2.5rem;
    letter-spacing: -0.02em;
  }

  @media (max-width: $sm-max-width) {
    padding: 2em 1em;

    h1 {
      font-size: 2rem;
    }
  }
}

.offerContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 1.5rem 1.875rem 1.5rem 1.25rem;
  align-items: center;
  align-self: stretch;
  border-radius: 1rem;
  background-color: $green-50;
}

.offerDetailsContainer {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  justify-content: center;
  align-items: flex-start;
  gap: 0.375rem;
}

.upsellSubscriptionTermText {
  color: $type-light-primary;
  text-transform: uppercase;
  font-family: $font-default;
  font-size: 1rem;
  font-weight: 700;
}

.pricesContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 0.25rem;
}

.upsellSubscriptionPriceText {
  color: $green-500;
  font-family: $font-default;
  font-size: 3.375rem;
  font-weight: 600;
}

.selectedSubscriptionPriceText {
  color: $type-light-secondary;
  font-family: $font-default;
  font-size: 1.75rem;
  font-weight: 600;
  text-decoration: line-through;
}

.upgradeIncentive {
  font-family: $font-default;
  font-size: 1rem;
  color: $type-light-primary;
  font-weight: 700;

  span {
    color: $type-light-secondary;
    text-decoration: line-through;
    font-weight: 500;
  }
}

.specialOfferChipSmall {
  display: flex;
  padding: 0.125rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  position: absolute;
  left: 1rem;
  top: -0.5625rem;

  border-radius: 6.25rem;
  border: 2px solid white;
  background: $green-500;

  p {
    font-family: $font-default;
    font-size: 0.75rem;
    font-weight: 700;
    color: $type-dark-primary;
  }
}

.heroImageSection img {
  width: 100%;
  max-width: 32.75rem;
}

.ctaSection {
  padding: 3em 2.5em 2.5em;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1.5em;

  @media (max-width: $sm-max-width) {
    padding: 2em 1em;
  }
}

.ctaButtons {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1em;
}

.ctaPrimary {
  background-color: $bg-dark-primary;
  color: $type-dark-primary;
}

.commitment {
  font-size: 0.9rem;
}
