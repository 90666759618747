@import '../../styles/variables';

.main {
  composes: celledSection from '../../styles/celledSection.module.scss';
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1em;

  @media (max-width: $sm-max-width) {
    border-radius: 0;
  }
}

.form {
  composes: form from '../../styles/form.module.scss';

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1.5em;

  input[type='text'] {
    flex-grow: 1;
    border: none;
    margin: 0;
  }

  @media (min-width: $md-min-width) and (max-width: $md-max-width), (min-width: $xl-min-width) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.button {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

@keyframes promo-code-shake {
  0% {
    transform: translate(1px, 0);
  }
  10% {
    transform: translate(-1px, 0);
  }
  20% {
    transform: translate(-3px, 0);
  }
  30% {
    transform: translate(3px, 0);
  }
  40% {
    transform: translate(1px, 0);
  }
  50% {
    transform: translate(-1px, 0);
  }
  60% {
    transform: translate(-3px, 0);
  }
  70% {
    transform: translate(3px, 0);
  }
  80% {
    transform: translate(-1px, 0);
  }
  90% {
    transform: translate(1px, 0);
  }
  100% {
    transform: translate(1px, 0);
  }
}
