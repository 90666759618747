@import '../../styles/variables';

.carouselWrapper {
  composes: container from '../../styles/container.module.scss';
  max-width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 4em;
  align-items: center;
  min-height: 48em;
  padding-top: 3em;
  padding-bottom: 3em;

  @media (max-width: $sm-max-width) {
    min-height: 40em;
  }
}

.fadeEnter {
  opacity: 0;
  transform: translateX(70%);
}

.fadeEnterActive {
  opacity: 1;
  transform: translateX(0%);
}

.fadeExit {
  opacity: 1;
  transform: translateX(0%);
}

.fadeExitActive {
  opacity: 0;
  transform: translateX(-70%);
}

.fadeEnterActive,
.fadeExitActive {
  transition: opacity 400ms, transform 600ms;
}

.reviewBlock {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pullquote {
  font-family: $font-title;
  text-align: center;
  max-width: 750px;
  margin: 40px auto;
  font-size: 2rem;
  line-height: 1.1em;

  @media (min-width: $md-min-width) {
    font-size: 3.5em;
  }
}

.authorName {
  font-family: $font-title;
  font-size: 1.5rem;
  color: $type-light-secondary;
}

.publicationIcon {
  max-height: 64px;
  border-radius: 0.25rem;
  overflow: hidden;
}

.moreReviewsButton {
  border: 1px solid $gray-400;
  padding: 1.25rem 2rem;
  border-radius: 9999px;
  text-align: center;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  color: $type-light-primary;
  text-decoration: none;

  &:hover {
    border-color: #000;
  }
}
