.rescuePage {
  margin: 50px auto;
  width: 800px;

  .splitContainer {
    justify-content: space-between;
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    width: 100%;

    > * {
      width: 45%;
      flex: unset;
      margin: 0;
      align-items: center;
      flex-direction: column;
    }

    .button {
      width: 90%;
    }

    .rescueLeft {
      display: flex;
      align-items: center;
      border-right: 1px solid rgba(0, 0, 0, 0.15);

      p {
        margin-top: 0.5rem;
        max-width: 280px;
        color: rgba(0, 0, 0, 0.75);
      }

      div {
        margin-top: 50px;
      }
    }
  }

  h2,
  h5 {
    text-transform: none;
    margin: 0;
  }

  h2 {
    font-weight: 800;
    line-height: 1;
    font-size: 58px;
  }

  h5 {
    font-size: 26px;
    line-height: 1;
    font-weight: 300;
  }

  p {
    margin-bottom: 0;
    font-size: 1.4rem;
  }

  .rescueForm {
    width: 100%;
    margin: 0;

    p {
      margin-top: 0;
    }

    > div {
      margin-top: 3rem;

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-top: 1rem;
      }
    }

    select {
      min-width: 140px;
      font-size: 1rem;
      font-weight: 500;
      margin-top: 0.5rem;
      background-color: #fff;
    }

    input[type='email'],
    input[type='password'],
    input[type='tel'],
    input[type='text'] {
      font-family: 'din-2014', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        'Noto Sans', sans-serif;
      font-weight: 300;
      font-size: 1.25rem; /* 20px is standard for Fi sites */
      font-feature-settings: normal;
      -moz-font-feature-settings: 'kern';
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      width: 100%;
      border: none;
      border-bottom: 1px solid #d8d8d8;
      margin: 0 0 1em 0;
      padding: 0.75em 0;
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    overflow-x: hidden;

    .rescueHero {
      width: 100%;
      overflow: hidden;
      position: relative;
      height: 300px;
      min-width: 375px;

      img {
        position: absolute;
        top: 0;
        left: -50vw;
      }
    }

    .splitContainer {
      flex-direction: column;

      > * {
        width: 100%;
        text-align: center;
      }

      h2 {
        margin-bottom: 1rem;
      }

      .rescueLeft {
        align-items: center;
        padding-bottom: 2rem;
        margin-bottom: 0;
        border-right: 0px;

        p {
          max-width: 75%;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .rescueRight {
        width: 90%;
        margin: 0 auto;
        padding: 3rem 0;
        background: #f9f9f9;
        border-radius: 4px;
      }

      .rescueForm {
        input {
          width: 85%;
        }
      }
    }
  }
}
