@import '../../../../styles/variables';
@import '../ItemTile/ItemTile.module';

.accessoriesModuleItemContainer.maker {
  .accessoriesModuleItem {
    .makerExtLink {
      display: flex;
      margin-top: -73px;
      float: right;
      margin-right: 24px;
    }
  }
  .accessoriesModuleItemDescription {
    margin-top: 24px;

    h5 {
      margin: 0;
      font-family: $font-default;
      font-weight: 600;
      size: 24px;
      line-height: 34px;
      letter-spacing: -0.01em;
      color: #000000;
    }
    p {
      margin-top: 8px;
      font-family: $font-default;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: #737373;
      text-align: left;
    }
  }
}
