@import '../../../../../styles/variables';

.frequencyTitle {
  margin-top: 32px;
  margin-bottom: 16px;
  font-family: $font-default;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.48px;
}

.frequencyContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 32px);
  margin-bottom: 32px;
  padding: 19px 16px;
  border-radius: 86px;
  border: 1px solid #bebebb;
}

.frequencyMinusButton,
.frequencyPlusButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: white;
  border: none;
  position: relative;
}

.frequencyMinusButton::before,
.frequencyPlusButton::before {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.frequencyMinusButtonDisabled,
.frequencyPlusButtonDisabled {
  opacity: 0.25;
  pointer-events: none;
}

.addToBagContainer :global(.collar-add-to-bag-button) {
  width: calc(100% - 48px);
  margin-bottom: 8px;

  @media (min-width: $lg-min-width) {
    width: 100%;
    margin-bottom: 0px;
    padding: 0px;
  }
}

.divider {
  height: 8px;
  width: 100%;
  background-color: #f3f3f2;

  @media (min-width: $lg-min-width) {
    display: none;
  }
}

:global(.product-details) .divider {
  margin: 0px -24px;
  width: calc(100% + 48px);
}

.benefitsContainer,
.reviewsContainer,
.faqContainer {
  display: flex;
  justify-content: center;
  width: calc(100% - 48px);
  padding: 0px 24px;
}

.skuOptionSelector {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 32px;
  width: 100%;
  gap: 16px;
}

.productHeader {
  margin-bottom: 12px;
  font-family: $font-default;
  font-size: 12px;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: 0.24px;
}

.productFooter {
  font-family: $font-default;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
}

.productTitle {
  margin-bottom: 12px;
  font-family: $font-default;
  font-size: 44px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -1.76px;
}

.productTitleDescription {
  margin-bottom: 12px;
  font-family: $font-default;
  font-size: 20px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.4px;
}
