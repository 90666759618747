@import 'variables';

.container {
  font-family: $font-default;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  max-width: 1320px;
  padding-left: $mobile-padding;
  padding-right: $mobile-padding;
}

.xsFullWidth {
  composes: container;

  padding-left: 0;
  padding-right: 0;

  @media (min-width: $sm-min-width) {
    padding-left: $mobile-padding;
    padding-right: $mobile-padding;
  }
}

.smFullWidth {
  composes: container;

  padding-left: 0;
  padding-right: 0;

  @media (min-width: $md-min-width) {
    padding-left: $mobile-padding;
    padding-right: $mobile-padding;
  }
}

.mdFullWidth {
  composes: container;

  padding-left: 0;
  padding-right: 0;

  @media (min-width: $lg-min-width) {
    padding-left: $mobile-padding;
    padding-right: $mobile-padding;
  }
}
