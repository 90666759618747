@import '../../styles/variables';

.main {
  background-color: #ffff01;

  line-height: 1.2;
  letter-spacing: -0.01em;
  text-decoration: none;
  color: black;

  width: 100%;
  z-index: 100;
  padding: 21px 0;
  font-family: $font-title;

  .content {
    text-align: center;
    padding: 3px;
    flex: 1;
  }

  .dismissButton {
    position: absolute;
    height: inherit;
    top: 32px;
    right: 30px;
    line-height: 12px;
    cursor: pointer;

    .dismissIcon {
      width: 12px;
      height: 12px;
      display: inline-block;
      background: url('../../assets/images/close-button.svg');
    }
  }
}

body:has(:global(.ecom-flow-revamp)) .main {
  height: 50px;
}
