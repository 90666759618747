@import '../../styles/variables';

.subscribedPetContainer {
  border-top: 1px solid #bebebe;
  margin-top: 30px;
  padding-top: 15px;
  width: 100%;
}

.subscribedPet {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 24px;
  padding: 5px 20px;
  background-color: #e0e0dd;
  margin-top: 15px;
}
.subscribedPetAvatar {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  margin-right: 10px;
}

.subscribedPetDescription {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.subscribedPetSavings {
  color: #0a7e00;
}

.subscribedPetFooter {
  margin-top: 15px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}
