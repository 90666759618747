@import '../../../../../styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  border-top: 8px solid #f3f3f2;
  border-bottom: 8px solid #f3f3f2;

  @media (min-width: 1025px) {
    border-top: none;
    border-bottom: none;
    max-width: 834px;
  }
}

.heading {
  font-family: $font-default;
  font-size: 32px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.64px;
  margin-top: 32px;
  margin-bottom: 24px;
  width: 100%;
  text-align: center;

  @media (min-width: 1025px) {
    font-size: 44px;
    line-height: 100%;
    letter-spacing: -1.76px;
    margin-top: 96px;
  }
}

.hardwareImage img {
  object-fit: cover;
  max-width: 100%;
  @media (min-width: 1025px) {
    width: 834px;
    border-radius: 20px;
  }
}

.subHeading {
  margin-top: 32px;
  margin-left: 24px;
  margin-bottom: 28px;
  font-family: $font-default;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
}

.valueProps > div {
  background-color: unset;
  padding: 0 24px !important;
  gap: 24px !important;
  margin-bottom: 48px;
  @media (min-width: 1025px) {
    display: grid !important;
    gap: 40px 48px !important;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    margin-bottom: 72px;
  }
}

.valueProps > div > div > div:first-child,
.valueProps > div > div > div:first-child svg {
  height: 28px !important;
  width: 28px !important;
  padding: 0;
  @media (min-width: 1025px) {
    height: 48px !important;
    width: 48px !important;
  }
}

.valuePropHeader {
  font-family: $font-default;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
}

.valuePropHeaderBold {
  font-weight: 600;
}

.mobileAppImage {
  background: radial-gradient(55.5% 55.5% at 50% 50%, #ffffff 45%, #ebe8e6 100%);
  padding-top: 30px;
  width: 100%;

  @media (min-width: 1025px) {
    border-radius: 20px;
    width: 834px;
  }
}

.mobileAppImage picture {
  display: flex;
  justify-content: center;
}

.mobileAppImage img {
  max-width: 300px;
}
