@import '../../../../styles/variables';

.roundButton {
  margin: 1px;
  padding: 9px;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid transparent;
  display: inline-block;
  border-color: #bebebe;
  box-sizing: border-box;

  &.selected {
    border-color: black;
    border-width: 2px;
    margin: 0;
  }

  &.unavailable {
    opacity: 0.4;
    cursor: default;
  }

  .contents {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    overflow: hidden;

    > * {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: $sm-max-width), (min-width: $lg-min-width) and (max-width: $lg-max-width) {
    .contents {
      width: 48px;
      height: 48px;
    }
  }
}

:global(.ecom-flow-revamp) .roundButton {
  padding: 4px;
}

:global(.ecom-flow-revamp) .roundButton .contents {
  height: 48px;
  width: 48px;
}
