@import '../../../../../styles/variables';

.container {
  composes: container from '../../../../../styles/container.module.scss';
  padding: 3em 0;
}

.title {
  font-family: $font-default;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.5em;
  padding-left: 1em;
  padding-right: 1em;
}

.subItems {
  padding-left: $mobile-padding;
  padding-right: $mobile-padding;
  gap: 2.5em;
  display: flex;
  flex-direction: column;

  @media (min-width: $md-min-width) {
    flex-direction: row;
  }
}

.subItem {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.subItemImageContainer {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 1em;
  background-color: #f5f5f2;
  box-sizing: border-box;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: $md-min-width) {
    max-width: 390px;
  }

  & > picture img {
    max-width: 100%;
    object-fit: cover;
    mix-blend-mode: multiply;
  }
}

.subItemName {
  font-size: 1.5em;
  font-weight: 600;
}
