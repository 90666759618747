@import '../../../../styles/variables';

.main {
  display: flex;
  flex-direction: column;
  gap: 1em;

  .buttonOutline {
    font-family: $font-default;
    font-weight: 300;
    border: 1px solid #000000;
    color: #000000;
    font-size: 1.25rem;
    font-feature-settings: normal;
    -moz-font-feature-settings: 'kern';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0.45rem 1rem 0.25rem;
    margin-top: 0.5rem;
    min-width: 9.5rem;
    line-height: 1.5;
    text-align: center;
    text-transform: uppercase;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
  }
}
