@import '../../styles/variables';

.container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 0 1em;
}

.container svg {
  position: absolute;
  right: 24px;
  pointer-events: none;
}

.selectContainer {
  position: relative;
  flex-grow: 1;
  box-sizing: border-box;
  width: 100%;
  padding: 1.5em 2em;
  appearance: none;

  border: 1px solid #bebebb;
  border-radius: 2em;

  font-family: $font-default;
  font-size: 16px;
  color: #848484;
}

.selectContainer.valid {
  color: black;
}
