@import '../../styles/variables';

.container {
  background-color: #ffff01;
  width: 100%;
  z-index: 100;
  padding: 21px 0;

  .content {
    text-align: center;
    flex: 1;

    @media (max-width: $md-max-width) {
      text-align: left;
      margin-left: 24px;
    }

    .top {
      .icon {
        margin-right: 10px;
      }

      text-transform: uppercase;
      font-family: $font-title;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: -0.04em;
      font-weight: 600;
    }
    .bottom {
      font-family: $font-title;
      font-size: 16px;
      line-height: 19px;
    }
  }
}
