@import '../../../../../styles/variables';

.container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 48px);
  max-width: 415px;
  margin: 16px 24px 0px 24px;
  padding: 0 24px;
}

.newLabel {
  width: fit-content;
  align-self: center;
  font-family: $font-title;
  font-size: 1.2em;
  background-color: $collar-yellow;
  border-radius: 100px;
  padding: 6px 12px;
  font-weight: 700;
  letter-spacing: 1.92px;
}

.supplementsText {
  align-self: center;
  margin: inherit; // Don't apply default h1 margin
  font-family: $font-title;
  font-size: 45px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -1px;
}

.pitch {
  align-self: center;
  margin-top: 8px;
  font-family: $font-default;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  text-align: center;
  .specialOffer {
    border-radius: 6px;
    padding: 8px 12px;
    background-color: $collar-yellow;
    font-family: $font-title;
    font-size: 20px;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: -0.2px;
    text-align: center;
  }

  .specialOfferValue {
    font-family: $font-title;
    font-size: 20px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.2px;
  }
}

.imageGalleryContainer :global(.more-photos) {
  display: none;
}

.imageGalleryContainer picture {
  width: 100% !important;
  margin: 0 !important;
  margin-right: 15px !important;
}

.imageGalleryContainer :global(.flickity-page-dots) {
  display: initial !important;
  bottom: 20px !important;
}

.imageGalleryContainer.hasNotification {
  position: relative;
  margin-bottom: 22px;
}

.skuOptionSelector {
  display: flex;
  flex-direction: row;
  align-items: space-between;
  gap: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.skuOption {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 16px 24px 16px 24px;
  border: 2px solid #bebebb;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
}

.skuOption img {
  height: 60px;
}

.skuOptionSelected {
  border: 2px solid #242424;
}

.skuOptionTitle {
  font-family: $font-default;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  letter-spacing: -0.02em;
}

.skuOptionDescription {
  margin-top: 4px;
  margin-bottom: 16px;

  font-family: $font-default;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  color: #737373;
}

.skuOptionKeyBenefitItem {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-bottom: 12px;
}

.skuOptionKeyBenefitItemImageContainer,
.skuOptionKeyBenefitItemImageContainer svg {
  width: 24px;
  height: 24px;
  align-self: flex-start;
}

.skuOptionKeyBenefitItemText {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.skuOptionKeyBenefitItemTitle {
  font-family: $font-default;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  letter-spacing: -0.01em;
}

.skuOptionKeyBenefitItemDescription {
  font-family: $font-default;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  color: #737373;
}

.whatsInsideExpandButton,
.whatsInsideCollapseButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  margin-top: 16px;
  margin-bottom: 32px;
  cursor: pointer;
}

.ingredients {
  margin-bottom: 16px;
  font-family: $font-default;
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
  color: #737373;

  @media (min-width: $lg-min-width) {
    max-width: 814px;
  }
}

.ingredientsBold {
  font-weight: 600;
}

.ingredientsDisclaimer {
  margin-bottom: 32px;

  font-family: $font-default;
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
  color: #737373;

  @media (min-width: $lg-min-width) {
    max-width: 814px;
  }
}

:global(.flickity-page-dots) {
  background-color: transparent !important;
}

:global(.flickity-page-dots) li {
  width: 10px !important;
  height: 10px !important;
  margin: 0 8px !important;
}

.mobileCTAContainer {
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  gap: 16px;
  background-color: white;
  border-top: 0.33px solid #00000040;
  box-shadow: 0px -2px 12px 0px rgba(0, 0, 0, 0.12);
  width: calc(100% - 32px);
  margin-top: 25px;
  padding: 0 16px 24px 16px;
  align-self: center;
  z-index: 2 !important; // Ensures CTAs sit on top of notification image with lower z-index.
}

.confirmOrderButton,
.addSupplementButton,
.notNowButton {
  width: 100%;
  margin-top: 24px;
  padding: 18px 32px 18px 32px;
  border-radius: 100px;

  background-color: black;
  color: white;
  border: none;

  font-family: $font-default;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  letter-spacing: -0.01em;
  cursor: pointer;

  @media (max-width: 400px) {
    padding: 18px;
  }
}

.confirmOrderButton:disabled {
  cursor: not-allowed;
  background-color: #e5e5e4;
  color: #9c9c9b;
}

.inAppCheckoutContainer {
  flex: 3;
  white-space: nowrap;
}

.notNowButton {
  flex: 1;
  background-color: white;
  color: black;
  border: 1px solid black;
  white-space: nowrap;
}

.orderSummaryContainer {
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  padding: 16px;
  background-color: #f3f3f2;
  border-radius: 8px;
}

.orderSummaryTitle {
  font-family: $font-default;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.6px;
  letter-spacing: -0.01em;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #bebebc;
}

.cartItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 16px 24px 16px 0;
  border-radius: 16px;
}

.cartItemDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.cartItemImage {
  display: flex;
  width: 64px;
  height: 64px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: white;
  margin-right: 16px;

  img {
    width: 64px;
    height: 64px;
    object-fit: contain;
  }
}

.cartItemText {
  align-self: flex-start;
}

.cartItemTitle {
  font-family: $font-default;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  margin-bottom: 4px;
}

.cartItemDescription {
  font-family: $font-default;
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
  color: #737373;
}

.cartItemPrice {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.cartItemPrice {
  align-self: flex-start;
  text-align: right;
  font-family: $font-default;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
}

.promotionContainer {
  display: flex;
  flex-direction: row;
  padding: 16px 24px;
  margin-top: 16px;
  justify-content: space-between;
  align-items: center;
  background-color: #fffa01;

  font-family: $font-default;
  font-size: 14px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.14px;
}

.taxContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 8px;
  padding: 0px 24px 0px 0px;

  font-family: $font-default;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.14px;
}

.totalDueContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  padding: 0px 24px 0px 0px;

  font-family: $font-default;
  font-size: 20px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.4px;
}

.testimonial {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  background-color: $collar-yellow;
  margin: 40px 0px 28px 0px;
  width: 100%;
  align-self: center;
}

.testimonialText {
  font-family: $font-title;
  font-size: 48px;
  font-weight: 700;
  line-height: 105%;
  letter-spacing: -0.96px;
  text-align: center;
}

.testimonialDescription {
  font-family: $font-default;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  text-align: center;
}

.testimonialDescriptionHighlight {
  font-weight: 600;
}

.termsAndConditionsContainer,
.recurringSubscriptionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  svg {
    width: 20px;
    height: 20px;
  }

  font-family: $font-default;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.16px;
  color: #737373;

  a {
    color: #737373;
    text-decoration: underline;
  }
}

.termsAndConditionsContainer {
  margin-bottom: 16px;
}

.recurringSubscriptionContainer {
  margin-bottom: 100px;
}

.mobileCTAButtons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
}

.mobileCTAFooter {
  font-family: $font-default;
  font-size: 12px;
  font-weight: 700;
  line-height: 140%;
  text-align: center;
  color: #9e9e9c;
}

.notification {
  position: absolute;
  bottom: 10px; // Position at the bottom of the image carousel
  left: 0;
  right: 0;
  width: fit-content;
  display: flex;
  background-color: $bg-light-primary;
  flex-direction: row;
  border-radius: 14px;
  border: 0.5px solid var(--Primitives-Gray-gray-100, #e5e5e4);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  align-items: center;
  padding: 12.45px;
  width: 298px; // Fixed width so it always looks like a mobile notification.
  margin: auto;
  z-index: 1 !important;

  @media (max-width: 370px) {
    width: auto; // Make notification width flexible when screen is narrow so it's not cut off.
    bottom: 0;
  }

  p {
    margin: 0;
  }

  .logo {
    width: 50px;
    margin-right: 13px;
  }

  .title {
    display: flex;
    flex-direction: row;
    text-align: center;
    gap: 3px;

    svg {
      width: 14px;
      height: 14px;
    }

    p {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: -0.125px;
      font-weight: 600;
    }
  }

  .description {
    p {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 16.8px */
      letter-spacing: -0.12px;
      font-family: $font-default;
      color: $type-light-primary;
    }
  }
}
