@import 'variables';

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: $font-default;

  h4 {
    font-size: 1.5rem;
    margin-bottom: 1.5em;
  }

  h5 {
    font-size: 16px;
  }

  input,
  select,
  textarea {
    font-family: $font-default;
    font-weight: 400;
    background: none;
    width: auto;
  }

  input:focus,
  select:focus,
  textarea:focus {
    outline: none;
  }

  input[type='email'],
  input[type='password'],
  input[type='tel'],
  input[type='text'],
  :global(.recurly-element) {
    width: auto;
    background-color: #ffffff;
    font-size: 1rem;
    padding: 1.5em 2em;
    box-sizing: border-box;
    border-radius: 2em;
    border: 1px solid #bebebb;
    margin: 0 0 1em;

    &.error {
      border-color: $red;
    }

    &:focus {
      outline: none;
    }
  }

  :global(.recurly-element) {
    height: 4em;
    width: 100%;
    padding: 0 2em;
  }

  textarea {
    border: 1px solid #d8d8d8;
    padding: 10px;
  }
}

.formSection {
  padding-bottom: 1em;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
}

.formRow {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  & > *,
  input[type='text'],
  select {
    flex: 1;
    width: 100%;
  }
}

.compact {
  flex: 1;

  .formSection {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .formRow {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    gap: 1em;
  }
}

.formActions {
  margin-bottom: 1em;
}

@media (min-width: $md-min-width) {
  .formRow {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
  }
}

.formError {
  font-family: $font-default;
  color: $red;
  line-height: 1.4em;

  a {
    color: $type-light-primary;
    text-decoration: underline;
  }
}

.mixedGiftingCartMessage {
  align-self: stretch;
  margin-bottom: 40px;

  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: #737373;
}

.giftDeliveryShippingOptions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 32px;
}

.giftDeliveryShippingOption {
  display: flex;
  height: 24px;
  padding: 16px 20px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 16px;
  border: 2px solid #bebebb;

  &Selected {
    border-color: black;
  }

  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  cursor: pointer;

  input[type='radio'] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    width: 20px;
    height: 20px;
    border: 2px solid black;
    border-radius: 50%;
    margin-right: 10px;
    outline: none;
    cursor: pointer;
    background-color: white;

    &:checked {
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        background-color: black;
        border-radius: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

:global(.supplements-update-address-modal) input[type='text'] {
  padding: 18px 2em;
}
