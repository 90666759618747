@import '../../../../styles/variables';

:global(.s3-popup-content):has(.dynamicSizingMain) {
  animation: none;
}

:global(.s3-popup-overlay):has(.dynamicSizingMain) {
  height: 100vh;
}

div:has(:global(.s3-popup-body)):has(.dynamicSizingMain) {
  overflow: hidden;
}

:global(.s3-popup-body):has(.dynamicSizingMain) {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  border-radius: 24px;
  touch-action: manipulation; // Disable double tap to zoom on mobile
  position: relative;
}

:global(.s3-popup-content):has(.dynamicSizingMain) :global(.s3-popup-close-icon) {
  background-color: #e5e5e4;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  z-index: 4;
}

.dynamicSizingMain {
  z-index: 1;

  .dynamicSizingSection {
    background-color: #f3f3f2;
    padding: 40px 24px 24px 24px;
    display: flex;
    flex-direction: column;

    .header {
      font-family: $font-default;
      font-size: 32px;
      font-weight: 600;
      line-height: 120%;
      margin-bottom: 8px;
    }

    .subHeader {
      font-family: $font-default;
      font-size: 16px;
      font-weight: 600;
      line-height: 140%;
      margin-bottom: 24px;
    }

    .breedButton,
    .sizingButton {
      height: 60px;
      border-radius: 86px;
      border: 1px solid #bebebb;
      background-color: white;
      width: 100%;
    }

    .breedButton {
      display: flex;
      margin-bottom: 24px;
      justify-content: space-between;
      align-items: center;
      padding: 24px;
      cursor: pointer;
    }

    .buttonArrow {
      background-image: url(../../../../../../public/arrow.png);
      background-size: cover;
      height: 24px;
      width: 24px;
    }

    .breedPlaceholder {
      font-family: $font-default;
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;
      color: #737373;
    }

    .currentBreed {
      font-family: $font-default;
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;
      color: black;
    }

    .sizingButton {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px;
    }

    .weight {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .weight input {
      display: flex;
      align-items: center;
      text-align: center;

      font-family: $font-default;
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;
      color: black;

      width: 34px;
      border: none;
    }

    /* Remove the up and down arrows from number input */
    .weight input[type='number']::-webkit-inner-spin-button,
    .weight input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .weight input[type='number'] {
      appearance: textfield;
      -moz-appearance: textfield; /* Firefox */
    }

    .poundsText {
      font-family: $font-default;
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;
      color: #737373;
    }

    .minusButton,
    .plusButton {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      display: inline-block;
      padding: 0px;

      background-color: white;
      border: none;
      cursor: pointer;
    }

    .minusButton::before,
    .plusButton::before {
      content: '';
      position: absolute;
      top: -10px;
      right: -10px;
      bottom: -10px;
      left: -10px;
    }

    .minusButtonIcon {
      background-image: url(../../../../../../public/minus.png);
      background-size: cover;
      margin: 10px 4px 10px 4px;
      height: 4px;
      width: 16px;
    }

    .plusButtonIcon {
      background-image: url(../../../../../../public/plus.png);
      background-size: cover;
      height: 24px;
      width: 24px;
    }

    .sizingButtonError {
      border: 1px solid #b81f15;
    }

    .sizingErrorMessage {
      margin-top: 12px;
      font-family: $font-default;
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;
      color: #b81f15;
    }

    .sizingGuideResultContainer {
      display: flex;
      flex-direction: column;
      background-color: white;
      border-radius: 24px;
      margin-top: 24px;
      padding: 20px;
    }

    .sizingGuideResultContainer .resultHeader {
      font-family: $font-default;
      font-size: 24px;
      font-weight: 600;
      line-height: 120%;
      padding-bottom: 16px;
      border-bottom: 1px solid #f1f1ef;
    }

    .sizingGuideResultContainer .resultHeaderSize {
      color: #07a157;
    }

    .sizingGuideResultContainer .currentBreedAndWeightText {
      font-family: $font-default;
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;

      padding-top: 16px;
    }

    .sizingGuideResultContainer .currentBreedAndWeightBoldText {
      font-family: $font-default;
      font-size: 16px;
      font-weight: 600;
      line-height: 140%;
    }

    .sizingGuideResultContainer .percentageResultContainer {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 58px;
      margin-top: 16px;
      margin-bottom: 16px;
      gap: 2px;

      justify-content: space-between;
      align-items: center;
      overflow: hidden;
    }

    .sizingGuideResultContainer .percentageResultItem {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 8px 0px 8px 0cqb;
    }

    .sizingGuideResultContainer .percentageResultItem:first-child {
      background-color: #e1faee;
      border-radius: 12px 0px 0px 12px;
      font-family: $font-default;
      font-size: 14px;
      font-weight: 600;
      line-height: 140%;
      color: #00773e;
    }

    .sizingGuideResultContainer .percentageResultItem:not(:first-child) {
      background-color: #f3f3f2;
      font-family: $font-default;
      font-size: 14px;
      font-weight: 600;
      line-height: 140%;
      color: #737373;
    }

    .sizingGuideResultContainer .percentageResultItem:last-child {
      border-radius: 0px 12px 12px 0px;
    }

    .sizingGuideResultContainer .puppyRecommendationText {
      font-family: $font-default;
      font-size: 24px;
      font-weight: 600;
      line-height: 120%;
      color: black;
      padding-bottom: 12px;
    }

    .sizingGuideResultContainer .puppyRecommendationSizeText {
      color: #07a157;
    }

    .puppySpecialOfferSection {
      background-color: #fffa00;
      border-radius: 24px;
      margin-top: 12px;
      padding: 20px;
    }

    .puppySpecialOfferSection .puppySpecialOfferTitle {
      display: flex;
      flex-direction: row;
      padding-bottom: 12px;
      align-items: center;

      font-family: $font-default;
      font-size: 18px;
      font-weight: 600;
      line-height: 140%;
      color: black;
    }

    .puppySpecialOfferSection .pawIcon {
      background-image: url(../../../../../../public/paw.png);
      background-size: cover;
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }

    .puppySpecialOfferSection .puppySpecialOfferDescription {
      font-family: $font-default;
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;
    }

    .puppySpecialOfferSection .puppySpecialOfferBold {
      font-family: $font-default;
      font-size: 16px;
      font-weight: 600;
      line-height: 140%;
    }

    .sizingGuideResultContainer .selectSizeButton {
      height: 55px;
      border-radius: 55px;

      font-family: $font-default;
      font-size: 16px;
      font-weight: 600;
      line-height: 120%;

      background-color: black;
      border: none;
      color: white;
      cursor: pointer;
    }

    .sizingGuideResultContainer .stillNotSureText,
    .sizingGuideResultContainer .puppySpecialOfferText {
      font-family: $font-default;
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
      color: #737373;

      padding-top: 16px;
    }

    .sizingGuideResultContainer .stillNotSureBoldText,
    .sizingGuideResultContainer .puppySpecialOfferBoldText {
      font-family: $font-default;
      font-size: 14px;
      font-weight: 600;
      line-height: 140%;
    }
  }

  .sizeOptionSection {
    padding: 24px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    background-color: white;

    .header {
      font-family: $font-default;
      font-size: 16px;
      font-weight: 600;
      line-height: 140%;
    }

    .sizeOptionList > div {
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }
}

.breedSelectorContainer {
  overflow: hidden;
}

:global(.s3-popup-body):has(.dynamicSizingNoScroll) {
  overflow: hidden;
}

.breedSelector,
.breedSelectorHidden {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  border-radius: 24px;

  background-color: white;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);
}

.breedSelector {
  transform: translateX(0);
}

.breedSelector,
.breedSelectorHidden {
  display: flex;
  flex-direction: column;
  font-family: $font-default;
  align-items: center;

  .backButton {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: white;
    border: none;
    cursor: pointer;
    top: 28px;
    left: 1.5em;
  }

  .backButtonIcon {
    background-image: url(../../../../../../public/back.png);
    background-size: cover;
    height: 20px;
    width: 20px;
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }

  .headerText {
    font-family: $font-default;
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
  }

  .searchBarInput {
    width: calc(100% - 96px);
    max-width: 400px;
    line-height: 60px;
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 12px;
    padding-left: 56px;
    padding-right: 10px;

    border-radius: 86px;
    border: 1px solid #bebebb;

    background-image: url(../../../../../../public/search.png);
    background-position: 24px center;
    background-repeat: no-repeat;
    background-size: 20px 20px;

    font-family: $font-default;
    font-size: 16px;
    font-weight: 500;
  }

  ul {
    overflow-y: auto;
    padding-left: 24px;
    width: 100%;
    max-width: 400px;
    margin-bottom: 24px;
  }

  .breedListItem {
    list-style-type: none;
  }

  .breedListItemButton {
    display: flex;
    align-items: left;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;

    font-family: $font-default;
    font-size: 16px;
    font-weight: 600;
    line-height: 120%;
    cursor: pointer;
    border: none;
    color: black;
    background-color: white;
  }
}

.sizingBlock,
.stillUnsure {
  h3 {
    font-family: $font-default;
    font-size: 20px;
    line-height: 120%;
    font-weight: 700;
    color: #000000;
    margin-bottom: 0.1em;
  }

  .sizeDescription {
    margin-bottom: 1em;
  }

  .sizeDescription,
  .dogList {
    font-family: $font-default;
    font-size: 1rem;
    font-weight: 500;
    color: #737373;
  }

  a {
    color: #000000;
    text-decoration: underline;
  }

  p {
    color: #737373;
  }
}

@media (max-width: $md-min-width) {
  .sizeOptionList > div:not(:last-child) {
    border-bottom: 1px solid #f1f1ef;
  }
}

@media (min-width: $md-min-width) {
  .dynamicSizingMain {
    .sizeOptionList {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-auto-flow: column;
      gap: 2em;
    }

    .sizeOptionList > div:not(:nth-child(3n)) {
      border-bottom: 1px solid #f1f1ef;
    }

    .dynamicSizingSection {
      display: flex;
      flex-direction: row;
      gap: 24px;
    }

    .dynamicSizingDetailsSection,
    .dynamicSizingResultSection {
      width: 50%;
    }
  }
}
