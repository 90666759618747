@import '../../../../styles/variables';

.variantSelectorButtonGroup {
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  margin-bottom: 16px;

  @media (max-width: $lg-min-width) {
    width: 100%;
    margin-top: 0;
  }
}

.variantSelectorButton {
  margin-right: 8px;
  border: #bebebe 1px solid;

  font-weight: 600;
  font-family: $font-default;
  font-size: 1em;
  color: #737373;
  white-space: nowrap;

  @media (max-width: $lg-min-width) {
    padding: initial;
  }
}

.variantSelectorButtonSelected {
  border: #000000 2px solid;
  color: #000000;
}
