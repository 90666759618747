@import '../../styles/variables';

.container {
  composes: container from '../../styles/container.module.scss';

  h1 {
    font-family: $font-title;
  }

  p,
  a {
    font-family: $font-default;
    color: #000000;
  }
}
