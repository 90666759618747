@import '../../../../styles/variables';

.main {
  font-family: $font-default;

  h2 {
    letter-spacing: -0.02em;
  }
  h3 {
    letter-spacing: -0.02em;
  }
  .basicForm {
    display: flex;
    flex-direction: column;

    input[type='email'],
    input[type='password'],
    input[type='tel'],
    input[type='text'] {
      font-family: 'din-2014', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        'Noto Sans', sans-serif;
      font-weight: 300;
      font-size: 1.25rem; /* 20px is standard for Fi sites */
      font-feature-settings: normal;
      -moz-font-feature-settings: 'kern';
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      width: 100%;
      border: none;
      border-bottom: 1px solid #d8d8d8;
      margin: 0 0 1em 0;
      padding: 0.75em 0;
    }
  }

  p {
    margin: 20px 0;
    letter-spacing: -0.01em;
  }

  .wide {
    width: 100%;
  }

  .formRow {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin: auto -5px;
  }
  @media (min-width: 600px) {
    .formRow {
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
    }
  }

  .formRow > input[type='email'],
  .formRow > input[type='password'],
  .formRow > input[type='tel'],
  .formRow > input[type='text'],
  .formRow > select,
  .formRow > .react-select-container {
    flex: 1 1 0;
    margin: 1em 5px;
  }

  .actionContainer {
    display: flex;
    justify-content: center;

    & > *,
    & > .applePayButton {
      flex: 1;
      margin-left: 0;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .rescuePlacementDisclaimer {
    font-size: 14px;
    font-weight: 300;
    opacity: 0.75;
  }

  .textDanger {
    color: $red;
  }
}
