@import '../../../styles/variables';

.paymentContainer {
  width: 100%;

  h1 {
    width: auto;
    font-family: $font-default;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.02em;
    color: #000000;
    margin: 0 24px 32px 24px;
  }
}

.billingInfoContainer {
  padding: 0;
}

.success {
  color: white;
  background: $green-200;
  padding: 1em;
  text-align: center;
  position: fixed;
  width: 80%;
}

.errorAlert {
  position: absolute;
  @media (min-width: $lg-min-width) {
    top: 108px;
    right: 20px;
  }
  width: 361px;
  z-index: 9999;

  @media (max-width: $lg-max-width) {
    top: 80%;
    margin: 0 20px 0 20px;
    width: auto;
  }

  .errorAlertIcon {
    margin-right: 10px;
  }

  .errorAlertDismiss {
    color: #ffffff;
    padding: 14px 4px;
    cursor: pointer;
  }
}
