@import '../../../../styles/variables';

.button {
  font-family: $font-default;
  font-weight: 300;
  font-size: 1.25rem; /* 20px is standard for Fi sites */
  font-feature-settings: normal;
  -moz-font-feature-settings: 'kern';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.buttonOutline {
  font-weight: normal;
  border: 1px solid black;
  color: black;
}

.rescueBase {
  margin-bottom: 5px;
}

.textDanger {
  color: $red;
}
