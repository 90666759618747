@import '../../../../../styles/variables';

body:has(.container) {
  overflow: hidden;
}

:global(.s3-popup-body):has(.container),
.container button {
  touch-action: manipulation;
}

.container {
  display: flex;
  padding: 40px 24px 24px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: white;
  border-radius: 24px 24px 0px 0px;

  @media (min-width: 769px) {
    border-radius: 24px;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;
}

.body {
  width: 100%;
}

.closeIcon {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background: #e5e5e4;
  cursor: pointer;
}

.title {
  margin-bottom: 24px;

  font-family: $font-default;
  font-size: 20px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.4px;
}

.cartItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.promotionContainer {
  display: flex;
  flex-direction: row;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  background-color: #fffa01;
  border-radius: 16px;

  font-family: $font-default;
  font-size: 14px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.14px;
}

.taxContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 8px;
  padding: 0px 24px;

  font-family: $font-default;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.14px;
}

.totalDueContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 24px;
  padding: 0px 24px;

  font-family: $font-default;
  font-size: 20px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.4px;
}

.confirmOrderButton {
  display: flex;
  width: 100%;
  padding: 18px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 100px;
  border: none;
  background-color: black;
  color: white;

  font-family: $font-default;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.16px;
  cursor: pointer;
}

.confirmOrderButton:disabled {
  background-color: #e5e5e4;
  color: #737373;
  cursor: not-allowed;
}

.continueShoppingButton {
  width: 100%;
  padding: 18px 0px;
  margin-top: 8px;
  border-radius: 100px;
  background: white;
  color: black;

  font-family: $font-default;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.16px;
  cursor: pointer;
  border: 1px solid black;
}

.disclaimer {
  margin-top: 8px;
  font-family: $font-default;
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
  color: #737373;
  text-align: center;

  a {
    color: #737373;
    text-decoration: underline;
    line-height: 140%;
  }
}

.cartItemsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
  max-height: calc(100vh - 500px);
  overflow: auto;
}

.cartItem {
  display: flex;
  flex-direction: row;
  background-color: #f3f3f2;
  border-radius: 16px;
  padding: 16px 24px;
}

.cartItemTitle {
  font-family: $font-default;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
}

.cartItemDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.cartItemDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.cartItemImage {
  display: flex;
  width: 64px;
  height: 64px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: white;

  img {
    width: 64px;
    height: 64px;
    object-fit: contain;
  }
}

.cartItemText {
  align-self: flex-start;
}

.cartItemTitle {
  font-family: $font-default;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
}

.cartItemDescription {
  font-family: $font-default;
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
  color: #737373;
}

.cartItemPrice {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.cartItemPrice {
  align-self: flex-start;
  text-align: right;
  font-family: $font-default;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
}

.cartItemRemoveButton {
  margin-right: -12px;
  cursor: pointer;
}

.cartItemDescriptionCrossedOut {
  text-decoration: line-through;
}
