@import '../../styles/variables';

.nanoBanner {
  z-index: 100;
  padding: 20px 68px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 92px;
  border: none;

  .textContainer {
    display: flex;
    flex-direction: row;

    .image {
      font-family: $font-title;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      text-transform: uppercase;
    }

    .titleContainer {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
    }

    .title {
      text-align: left;
      align-self: stretch;
      font-family: $font-title;
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      letter-spacing: -0.52px;
      color: black;
    }

    .subtitle {
      text-align: left;
      align-self: stretch;
      font-family: $font-default;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      color: black;
    }
  }

  .countdown {
    display: flex;
    align-items: center;
    height: 100%;

    .expiresSoonDesktop {
      display: flex;
      flex-direction: column;
      text-align: right;
      margin-right: 16px;
      font-family: $font-default;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      letter-spacing: 0.28px;
      text-transform: uppercase;
      color: black;
    }

    .expiresSoonMobile {
      display: none;
      color: black;
    }

    .countdownClock {
      display: flex;
      flex-direction: row;
      align-items: center;

      .hours,
      .minutes,
      .seconds {
        display: flex;
        width: 48px;
        height: 48px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: linear-gradient(225deg, #725b16 0%, #000 100%);
        backdrop-filter: blur(2px);
        text-align: center;
        text-shadow: 0px 4px 22px rgba(0, 0, 0, 0.02);

        font-family: $font-default;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: -0.48px;
        color: white;
      }

      .timeSeparator {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0px 8px;
        font-family: $font-default;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        color: black;
      }
    }
  }
}

@media (max-width: $sm-max-width) {
  .nanoBanner {
    padding: 12px 16px;

    .textContainer {
      height: 100%;
      .image {
        display: none;
      }

      .titleContainer {
        height: 100%;
        display: flex;
        justify-content: center;
        margin: 0px;
      }

      .title {
        font-size: 20px;
      }

      .subtitle {
        font-size: 14px;
      }
    }

    .countdown {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .expiresSoonDesktop {
        display: none;
      }

      .expiresSoonMobile {
        display: flex;
        margin-right: 0px;
        margin-bottom: 4px;
        font-size: 10px;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: 0.2px;
      }

      .countdownClock {
        .hours,
        .minutes,
        .seconds {
          width: 32px;
          height: 32px;
          font-size: 16px;
        }

        .timeSeparator {
          margin: 0px 4px;
        }
      }
    }
  }
}

.nanoBanner {
  background: linear-gradient(289deg, #fffa01, #ffba24, #fffa01);
  background-size: 600% 600%;
  -webkit-animation: BannerAnimation 16s ease infinite;
  -moz-animation: BannerAnimation 16s ease infinite;
  animation: BannerAnimation 16s ease infinite;
}

@-webkit-keyframes BannerAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes BannerAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes BannerAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
