@import '../../../../../styles/variables';

.valueProp {
  font-family: $font-default;
  font-size: 1em;
  color: #000000;
}

.valuePropHeader {
  font-family: $font-default;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
}

:global(.ecom-flow-revamp) :global(.nano-value-props) {
  background-color: transparent;
  padding: 0px;
  margin-bottom: 24px;
}
