@import '../../styles/mixins';
@import '../../styles/variables';

.container {
  @include webview-full-height();

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  background: #f3f3f2;

  @media (max-width: $lg-max-width) {
    background: #ffffff;
  }
}

.desktopTopBar {
  height: 88px;
  width: 100%;
  background: #ffffff;
  margin-bottom: 40px;

  // Hide on mobile devices
  @media (max-width: $lg-max-width) {
    display: none;
  }
}

.content {
  background: #ffffff;
  width: 656px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  border-radius: 24px;

  h1 {
    text-align: left;
    width: 100%;
    margin-left: 40px;
    letter-spacing: -1px;
  }

  @media (max-width: $lg-max-width) {
    width: 100%;
    border-radius: 0;
  }
}

.section {
  width: 100%;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.confirmationContainer {
  @include webview-full-height();

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;

  .section {
    justify-content: center;
  }

  .checkIcon {
    width: 100%;
    text-align: center;
    svg {
      width: 100%;
    }
  }

  h2 {
    font-family: $font-title;
    font-size: 2.5em;
    font-weight: 700;
    letter-spacing: -0.02em;
    text-align: center;
    margin-top: 0.5em;
    margin-bottom: 0;
  }

  p {
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    margin-top: 0.25em;
    text-align: center;
    padding: 0;
    color: $type-light-secondary;
  }
}

.desktopOnly {
  // Hide on mobile devices
  @media (max-width: $lg-max-width) {
    display: none;
  }
}

.mobileOnly {
  // Hide on non-mobile devices
  @media (min-width: $lg-min-width) {
    display: none;
  }
}

.errorAlert {
  position: fixed;
  top: 108px;
  right: 20px;
  width: 361px;

  @media (max-width: $lg-max-width) {
    position: initial;
    margin-top: -160px;
    margin: -160px 20px 0 20px;
    z-index: 9999;
    width: auto;
  }

  .errorAlertIcon {
    margin-right: 10px;
  }
}

.errorAlertDismiss {
  color: #ffffff;
  padding: 14px 4px;
  cursor: pointer;
}

.actions {
  box-sizing: border-box;
  padding: 1em 1.5em;
  width: 100%;

  button {
    width: 100%;
  }

  .buttonSpacer {
    height: 1em;
  }
}
