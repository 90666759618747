@import '../../../../styles/variables';

.header {
  font-size: 16px;
  font-family: $font-default;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #000000;
}

.peekContainer {
  display: flex;
  justify-content: space-between;
  color: #737373;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.01em;

  .paymentDetails {
    display: flex;
    gap: 1em;
  }
}
