@import '../../../../../styles/variables';

.nanoProductDetails {
  width: 100vw;
}

.testimonialContainer,
.featuresContainer,
.socialProofContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.testimonialTitle {
  color: black;
  text-align: center;
  font-family: $font-default;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -1.76px;
  margin-top: 80px;
  margin-bottom: 32px;
}

.testimonialImageContainer {
  display: flex;
  height: 521px;
  max-width: 926px;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.16) 0%, rgba(0, 0, 0, 0.16) 100%),
    url('../../../../../assets/images/nano/testimonial.jpeg') lightgray 50% / cover no-repeat;
  background-size: cover;
  background-position: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  border-radius: 16px;
}

.testimonialStars {
  display: flex;
  gap: 8px;
}

.testimonialStars svg {
  height: 30px;
  width: 30px;
}

.testimonialStars svg path {
  fill: white;
}

.testimonialMessage {
  text-align: center;
  font-family: $font-default;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.64px;
  color: white;
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.08);
  padding: 0 100px;
}

.testimonialUser {
  text-align: center;
  font-family: $font-default;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.4px;
  color: white;
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.08);
}

.featuresContainer {
  display: flex;
  align-items: center;
  padding-top: 80px;
  gap: 32px;
  width: initial;
}

.featureContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 40px;
  max-width: 926px;
}

.featureDescription {
  font-family: $font-default;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.56px;
  color: #000;
}

.featureContainer:nth-of-type(2) {
  flex-direction: row-reverse;
}

.featureIcon {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}

.featureImage {
  margin-top: 24px;
  object-fit: contain;
  max-height: 340px;
}

.socialProofTitle {
  align-self: stretch;
  color: black;
  text-align: center;
  font-family: $font-default;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -1.76px;
  margin-top: 80px;
  margin-bottom: 32px;
}

.socialProofContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialProofImageContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  row-gap: 24px;
  flex-wrap: wrap;
  max-width: 1000px;
}

.socialProofImage {
  display: flex;
  width: 212px;
  height: 212px;
  padding: 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
}

.socialProofImageCarousel {
  display: none;
}

@media (max-width: 1025px) {
  .testimonialContainer,
  .featuresContainer,
  .socialProofContainer {
    border-top: 8px #f3f3f2 solid;
    width: calc(100vw - 32px);
    padding: 0px 16px;
    margin-bottom: 24px;
  }

  .testimonialTitle {
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -0.64px;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .testimonialImageContainer {
    gap: 24px;
    height: 400px;
  }

  .testimonialStars svg {
    height: 22px;
    width: 22px;
  }

  .testimonialMessage {
    padding: 0 24px;
    font-size: 22px;
  }

  .featuresContainer {
    padding-top: 24px;
  }

  .featureContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
  }

  .featureContainer:nth-of-type(2) {
    flex-direction: column;
  }

  .featureDescription {
    text-align: center;
    font-size: 22px;
    letter-spacing: -0.44px;
  }

  .featureIcon {
    display: none;
  }

  .featureImage {
    margin-top: 24px;
    object-fit: contain;
    max-height: 300px;
  }

  .socialProofContainer {
    width: 100vw;
    padding: 0;
    margin-bottom: 72px;
  }

  .socialProofTitle {
    font-size: 32px;
    margin-top: 24px;
    margin-bottom: 24px;
    line-height: 120%;
    letter-spacing: -0.64px;
    padding: 0 16px;
  }

  .socialProofImageContainer {
    display: none;
  }

  .socialProofImageCarousel {
    display: flex;
    height: 212px;
    width: 100%;
  }

  .socialProofImageCarousel > div {
    height: 212px;
    width: 100%;
  }

  .socialProofImageCarousel button {
    display: none;
  }

  .socialProofImageCarousel :global(.flickity-page-dots) {
    bottom: -50px;
  }

  .carouselCell {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 212px;
    width: 50%;
    max-width: 212px;
    margin: 0px 4px;
  }

  .socialProofImage {
    width: 100%;
    object-fit: contain;
    max-height: 212px;
  }
}
