@import '../../../../styles/variables';

.container {
  composes: mdStacked from '../../../../styles/splitContainer.module.scss';
  padding: $body-top-padding 0;

  h2 {
    font-family: $font-title;
    margin-top: 0;
    font-size: 4.5rem;
    font-weight: 700;
    margin-bottom: 1em;
  }

  h4 {
    margin-top: 0;
    font-size: 1.5rem;
    font-weight: 600;
  }

  @media (max-width: $md-max-width) {
    h2 {
      font-size: 3rem;
    }
  }
}

.mobileBreadcrumbs {
  composes: container from '../../../../styles/container.module.scss';
  padding-top: 1em;
  display: none;

  @media (max-width: $sm-max-width) {
    display: block;
  }
}

.titleColumn {
  composes: container from '../../../../styles/container.module.scss';
  composes: stickyLeftColumn from '../../../../styles/splitContainer.module.scss';
}
