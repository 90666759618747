@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';

.section {
  padding: 0 24px;
  width: 100%;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &.billing {
    padding: 0;
  }
}

.confirmationContainer {
  @include webview-full-height();

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;

  .section {
    justify-content: center;
  }

  .checkIcon {
    width: 100%;
    text-align: center;
    svg {
      width: 100%;
    }
  }

  h2 {
    font-family: $font-title;
    font-size: 2.5em;
    font-weight: 700;
    letter-spacing: -0.02em;
    text-align: center;
    margin-top: 0.5em;
    margin-bottom: 0;
  }

  p {
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    margin-top: 0.25em;
    text-align: center;
    padding: 0;
    color: $type-light-secondary;
  }
}

.actions {
  box-sizing: border-box;
  padding: 1em 1.5em;
  width: 100%;

  button {
    width: 100%;
  }

  .buttonSpacer {
    height: 1em;
  }
}
