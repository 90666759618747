@import '../../styles/variables';

.footer {
  composes: container from '../../styles/container.module.scss';
  display: flex;
  flex-direction: row;
  gap: 1.5em;

  min-height: 35rem;
  padding-top: 4.5rem;
  padding-bottom: 4rem;

  & > * {
    flex-grow: 1;
  }

  @media (min-width: $md-min-width) {
    padding-bottom: 5.5rem;
  }
}

.footerSection {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.footerSectionNav {
  justify-content: space-between;
}

.footerNav {
  list-style: none;
  margin: 0;
  margin-bottom: 3rem;
  padding: 0;

  li {
    margin-bottom: 1rem;

    a {
      text-decoration: none;
      color: black;
      font-size: 1rem;
      font-weight: 600;
      transition: opacity 200ms ease-in-out;

      &:hover {
        opacity: 0.65;
      }

      &:active {
        opacity: 0.8;
      }
    }
  }
}

.copyright {
  font-size: 1rem;
  font-family: $font-default;

  p {
    line-height: 1.5em;
    margin-top: 0;
    margin-bottom: 0.25em;
  }
}

.emailFormHeader {
  margin-top: 0;
  font-family: $font-default;
  font-size: 1.5rem;
  line-height: 1.2em;
  letter-spacing: -0.01em;
  font-weight: 600;
  margin-bottom: 1rem;
}

.emailFormSubHeader {
  font-family: $font-default;
  font-size: 1rem;
  color: $type-light-secondary;
  padding-bottom: 1.5em;
  margin-bottom: 0.5em;
  font-weight: 500;
  max-width: 312px;
  line-height: 1.5em;
}

.joinEmailForm {
  background-color: white;
  height: 4rem;
  width: 100%;
  border-radius: 2rem;
  border: 1px solid $gray-400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  box-sizing: border-box;

  padding-left: 2rem;

  transition: all 0.2s ease-in-out;

  &:hover,
  &.focus {
    border-color: black;
    background-color: white;
    box-shadow: 0 0 0 1px black;
  }

  form {
    display: flex;
    flex-direction: row;
  }

  .emailInput,
  .submit {
    font-family: $font-default;
    font-weight: 600;
    background-color: transparent;
    border: none;
    height: 4rem;

    padding: 0;
    margin: 0;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .submit {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 4rem;
    width: 4rem;

    margin: 0;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .emailInput {
    flex-grow: 1;
    font-weight: 500;
    font-size: 1.1rem;
    flex-basis: 0;

    &::placeholder {
      color: black;
    }
  }
}

.emailFormSubmitted {
  margin-top: 1em;
  text-align: center;
}

.appStoreButtons {
  display: flex;
  flex-direction: row;
  gap: 1em;
  margin-top: 2.5em;
}

.appStoreButton {
  width: 100%;
  display: block;
  font-weight: 600;
  border: 1px solid $gray-400;
  padding: 1.25rem 2rem;
  border-radius: 9999px;
  text-align: center;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;

  &:hover {
    border-color: #000;
  }
}

.socialLinks {
  display: flex;
  flex-direction: row;
  margin-top: 1.5em;
}

.socialLink {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  margin: 0 0.25rem;

  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: hsla(0, 0%, 45%, 0.15);
    color: black;
  }
}

.copyrightDesktop {
  display: block;
}

.copyrightMobile {
  display: none;
  margin-top: 4em;

  p {
    text-align: center;
  }
}

@media (max-width: $md-max-width) {
  .footer {
    flex-direction: column;
    gap: 0;
  }

  .footerSection {
    width: 100%;
  }

  .copyrightDesktop {
    display: none;
  }

  .copyrightMobile {
    display: block;
  }

  .socialLinks {
    align-self: center;
  }
}

@media (max-width: $sm-max-width) {
  .emailFormHeader {
    font-size: 1rem;
  }

  .appStoreButtons {
    flex-direction: column;
  }
}
