@import '../../styles/variables';

.container {
  composes: container from '../../styles/container.module.scss';
  max-width: $sm-max-width;
  align-items: center;
  gap: 2em;
  padding-top: 3em;
  padding-bottom: 3em;

  h2 {
    font-family: $font-title;
    font-size: 4rem;
    font-weight: 700;
    text-align: left;
    width: 100%;
  }

  a {
    font-family: $font-default;
    font-size: 1rem;
    font-weight: 400;
    color: #000000;
    text-decoration: underline;
  }
}

.loginForm {
  composes: form from '../../styles/form.module.scss';
}

.formSection {
  composes: formSection from '../../styles/form.module.scss';
}

.formActions {
  composes: formActions from '../../styles/form.module.scss';
}

.actionLinks {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;

  & > a {
    text-align: center;
  }
}

.signUpError {
  font-family: $font-default;
  font-size: 1rem;
  font-weight: 400;
  color: $red;
  padding-right: 0.5em;
}
