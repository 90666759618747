@import '../../styles/variables';

.container {
  font-family: $font-default;
  width: 100%;
}

.chooserGroup {
  font-family: $font-default;
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }
}

.chooserGroupLabel {
  font-family: $font-default;
  font-weight: 600;
  margin-top: 1em;
}

.chooserGroupDescription {
  color: $type-dark-tertiary;
  font-size: 0.875rem;
}

.chooserOption {
  font-family: $font-default;
  font-size: 1rem;
  height: 7.5em;
  width: 100%;
  border: 1px solid #bebebe;
  border-radius: 1.5em;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  padding: 1em 2em;
  gap: 1em;

  &.selected {
    border-color: #000;
    border-width: 2px;

    // Hack to get elements to not jump around when border width changes. Typically you can use outline instead of
    // border, but we have a border-radius and Safari doesn't handle outline in combination with border-radius.
    padding: calc(1em - 1px) calc(2em - 1px);
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .radioSelected {
    border-radius: 100%;
    width: 8px;
    height: 8px;
    border: 12px solid #000000;
  }
  .radio {
    border-radius: 100%;
    border: 1px solid #bebebe;
    background-color: #ffffff;
    width: 30px;
    height: 30px;
  }
}

.container {
  &.compact {
    .chooserGroup {
      gap: 1.5em;
      margin-bottom: 2em;
    }

    .chooserOption {
      height: auto;
      padding: 0;
      border: none;
    }
  }
  &.horizontal {
    .chooserGroup {
      flex-direction: unset;
      gap: unset;
      width: unset;
    }

    .chooserOption {
      cursor: pointer;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      padding: 12px 0;
      gap: 16px;
      color: #737373;
      border: 1px solid #bebebe;
      align-items: center;
      justify-content: center;
      border-radius: 46px;
      margin-right: 8px;
      height: unset;
      width: 120px;

      &.selected {
        color: #000000;
        border: 2px solid #000000;
      }
    }
  }
  &.autoHeight {
    .chooserOption {
      height: auto;
      padding: 20px;

      &.selected {
        // To accomodate border width changing when selected. Typically you can use outline instead of
        // border, but we have a border-radius and Safari doesn't handle outline in combination with border-radius.
        padding: 19px;
      }
    }
  }
}

@media (max-width: $sm-max-width) {
  .chooserOption {
    padding: 1.5em;

    &.selected {
      // Hack to get elements to not jump around when border width changes. Typically you can use outline instead of
      // border, but we have a border-radius and Safari doesn't handle outline in combination with border-radius.
      padding: calc(1.5em - 1px);
    }
  }
}
:global(.ecom-flow-revamp) .chooserGroupLabelContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 16px 0;
}

:global(.ecom-flow-revamp) .chooserGroupLabel {
  font-family: $font-default;
  font-size: 14px;
  line-height: 140%;
  font-weight: 600;
  color: #737373;
  margin: 0;
}

:global(.ecom-flow-revamp) .chooserGroupLabelLine {
  flex-grow: 1;
  height: 2px;
  background-color: #e5e5e4;
}

:global(.ecom-nano-variant) .chooserGroupLabelLine {
  display: none;
}

:global(.ecom-flow-revamp) .chooserGroupLabelContainer {
  margin: 0px;
}

:global(.ecom-flow-revamp) .chooserOption {
  padding: 20px;
}

:global(.ecom-flow-revamp) :global(.size-options) .chooserOption {
  height: 84px;
}
