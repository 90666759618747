@import '../../styles/variables';

.icon {
  font-family: $font-default;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  text-align: left;
  width: 156px;

  &:first-child {
    margin-left: $mobile-padding;
  }

  &:last-child {
    margin-right: $mobile-padding;
  }

  @media (min-width: $md-min-width) {
    width: 168px;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: $light-gray;
  border-radius: 1.5em;
  width: inherit;
  height: 168px;

  & > picture img,
  svg {
    width: 40px;
    height: 40px;
  }
}

.iconTitle {
  font-family: $font-default;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5em;
  text-align: left;
}

.row {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1em;
  width: 100%;
  overflow: auto;
  padding-bottom: 1em;

  @media (min-width: $md-min-width) {
    gap: 3em;
  }
}
