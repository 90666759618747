@import '../../styles/variables';

@keyframes text-fade {
  0% {
    color: rgba(204, 204, 204, 0);
  }
  100% {
    color: rgba(204, 204, 204, 1);
  }
}

@keyframes shake-bag-grow-anim {
  0% {
    transform: scale(1);
  }
  98% {
    transform: scale(1.22) rotate(0turn);
  }
  100% {
    transform: scale(1.2) rotate(0.01turn);
  }
}

@keyframes shake-bag-shrink-anim {
  0% {
    transform: scale(1.2) rotate(0.01turn);
  }
  30% {
    transform: scale(1.3) rotate(-0.01turn);
  }
  100% {
    transform: scale(1);
  }
}

$app-bar-text-color: #000000;

.navBarContainer {
  z-index: 100;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.navBarContainer:has(:global(.nav-bar-hidden)) {
  pointer-events: none;
}

.main {
  composes: container from '../../styles/container.module.scss';
  font-family: $font-default;
  font-weight: 700;
  font-size: 1rem;
  color: $app-bar-text-color;
  background-color: #fff;
  height: $app-bar-height;
  padding: 0 0.5em 0 1.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  top: 0;
  z-index: 100;

  .leftNav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5em;
  }

  .rightNav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5em;
  }
}

.chatButton {
  cursor: pointer;
  padding: 16px;
}

.promotion {
  width: 100%;
  overflow: hidden;
}

.overlay {
  display: none;
}

.nav {
  .close {
    display: none;
  }

  .container {
    display: flex;
    align-items: center;
    gap: 2em;
  }

  .linkGroup {
    display: flex;
    align-items: center;
    gap: 2em;
  }

  .checkoutLinks {
    display: none;
  }

  .accountLinks {
    display: none;
  }

  .marketingLinks {
    display: none;
  }
}

.checkout {
  .checkoutLinks {
    display: flex;
  }

  .storeLinks {
    display: none;
  }
}

.toggle {
  cursor: pointer;
}

.link {
  color: $app-bar-text-color;
  font-weight: 700;
  text-decoration: none;
  transition: color ease-in-out 0.1s;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 0.4em;
    text-decoration-thickness: 0.15em;
  }
}

.shoppingBag,
.orderConfirmedShoppingBag {
  font-family: $font-default;
  display: flex;
  align-items: center;
  color: $app-bar-text-color;
  font-size: 18px;
  letter-spacing: 0.025rem;
  text-transform: uppercase;
  text-decoration: none;
  overflow: visible;
  padding: 0;
  margin: 0;
  z-index: 101;
}

.growing {
  animation: shake-bag-grow-anim 0.3s ease-in 1 forwards;
}

.shrinking {
  animation: shake-bag-shrink-anim 0.35s ease-in-out 1;
}

.open {
  .nav {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 103;
    box-sizing: border-box;
    width: 32em;
    max-width: 100%;
    flex-direction: column;
    background-color: #fff;
    padding: 0 5em;
    overflow: scroll;
    display: block;

    .container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 3em;
      padding: 1em 0 3em;
    }

    .linkGroup {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.5em;
    }

    .close {
      display: flex;
      align-items: center;
      height: $app-bar-height;
      position: sticky;
      top: 0;
      background-color: #fff;
    }

    .link {
      font-size: 2rem;
      font-weight: 600;

      &:hover {
        text-underline-offset: 0.3em;
        text-decoration-thickness: 0.1em;
      }
    }

    .checkoutLinks {
      display: none;
    }

    .storeLinks {
      display: none;
    }

    .accountLinks {
      display: flex;
    }

    .marketingLinks {
      display: flex;
    }
  }

  .overlay {
    display: block;
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 102;
    backdrop-filter: blur(10px);
  }

  &.checkout {
    .nav {
      .storeLinks {
        display: flex;
      }
    }
  }
}

@media (max-width: $sm-max-width) {
  .main {
    height: $app-bar-height-mobile;
  }

  .nav {
    display: none;
  }

  .open {
    .nav {
      padding: 0 1.5em;

      .close {
        height: $app-bar-height-mobile;
      }

      .link {
        font-size: 1.5rem;
      }

      .storeLinks {
        display: flex;
      }
    }
  }
}

body:has(:global(.ecom-flow-revamp)):has(.open) {
  overflow: hidden;
}

:global(.ecom-flow-revamp):has(.open) :global(.product-details) {
  opacity: 0.1;
  pointer-events: none;
}

:global(.ecom-flow-revamp) div:has(.open) .navBarContainer {
  z-index: 0;
}

div:has(:global(.promotionBanner)) :global(.ecom-flow-revamp) div:has(.open) .nav {
  margin-top: 64px;
  @media (min-width: 1025px) {
    margin-top: 76px;
  }
}

:global(.ecom-flow-revamp) :global(.navBar) {
  display: flex;
  flex-direction: row;
  padding: 0px 24px;
  position: absolute;
  top: 0px;
  background-color: unset;
  z-index: 10;
}

:global(.ecom-flow-revamp-light) :global(.navBar) {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(66, 66, 66, 0) 100%);
}

:global(.ecom-flow-revamp-light) :global(.navBarIconContainer) svg path {
  fill: white;
}

:global(.ecom-flow-revamp-light) :global(.navBarIconContainer) svg rect {
  fill: none;
}

:global(.ecom-flow-revamp-light) :global(.navBar) .storeLinks a {
  color: white;
}

:global(.ecom-flow-revamp-light) :global(.navBarIconContainer) .orderConfirmedShoppingBag svg path {
  fill: white;
}

:global(.ecom-flow-revamp-light) :global(.navBarIconContainer) .orderConfirmedShoppingBag svg rect {
  fill: white;
}

:global(.ecom-flow-revamp-light) :global(.navBarIconContainer) .orderConfirmedShoppingBag svg svg path {
  stroke: black;
}

:global(.ecom-flow-revamp-light) :global(.navBarIconContainer) .orderConfirmed text {
  fill: black;
}

.orderConfirmed text {
  text-transform: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.14px;
}

.supplementsBanner {
  @media (max-width: $md-max-width) {
    display: none;
  }
}

.orderConfirmedShoppingBag .emptyShoppingBackIcon {
  @media (max-width: $md-max-width) {
    display: none;
  }
  @media (min-width: $md-max-width) {
    display: block;
  }
}

.orderConfirmedShoppingBag .orderConfirmed {
  @media (max-width: $md-max-width) {
    display: flex;
    flex-direction: row;
    align-items: center;
    > div {
      margin: 0;
    }
  }
  @media (min-width: $md-max-width) {
    display: none;
  }
}
