@import '../../../../../styles/variables';

body:has(.container) {
  overflow: hidden;
}

:global(.s3-popup-body):has(.container),
.container button {
  touch-action: manipulation;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: min(470px, 100vw);
}

.header {
  display: flex;
  padding: 25px 20px;
  align-items: center;
  align-self: stretch;
  justify-content: flex-start;
  border-bottom: 1px solid #f1f1ef;
  gap: 12px;
}

.header svg {
  cursor: pointer;
}

.headerWithCloseIcon {
  justify-content: space-between;
}

.headerTitle {
  font-family: $font-default;
  font-size: 20px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.4px;
}

.frequencyContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 80px);
  margin: 20px 24px 0px 24px;
  padding: 19px 16px;
  border-radius: 86px;
  border: 1px solid #bebebb;
}

.frequencyMinusButton,
.frequencyPlusButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: white;
  border: none;
  position: relative;
}

.frequencyMinusButton::before,
.frequencyPlusButton::before {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.frequencyMinusButtonDisabled,
.frequencyPlusButtonDisabled {
  opacity: 0.25;
  pointer-events: none;
}

.frequencyValue {
  font-family: $font-default;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
}

.frequencyValueUnit {
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: #737373;
}

.whatIsRightContainer {
  display: flex;
  padding: 16px 20px;
  margin: 16px 24px;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  border-radius: 16px;
  background: #f3f3f2;
  cursor: pointer;
}

.whatIsRightTitle {
  font-family: $font-default;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.14px;
}

.saveButton {
  display: flex;
  width: calc(100% - 48px);
  padding: 18px 35px;
  margin-top: 87px;
  margin-bottom: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: center;

  border-radius: 100px;
  background: black;
  color: white;
  border: none;

  font-family: $font-default;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.16px;
  cursor: pointer;
}

.weightFrequencySelectorContainer {
  padding: 0px 20px;
}

.weightFrequencySelectorContainer :global(.frequency-container) {
  background: #f3f3f2;
  margin-bottom: 32px;
}
