@import '../../../styles/variables';

.fixedBackground {
  position: fixed;
  z-index: 0;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #122459;
  background-image: url(../../../assets/images/backgrounds/upgrade-background.png);
  background-size: cover;
  background-attachment: fixed;
}

.fullWidthWrapper {
  background-color: transparent;
  overflow: scroll;
  z-index: 1;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  color: white;
  padding-bottom: 3em;
}

.container {
  composes: container from '../../../styles/container.module.scss';
  align-items: center;
  gap: 2.5em;
  z-index: 1;

  h1 {
    font-family: $font-title;
    font-weight: 700;
    font-size: 4.5rem;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0.2em;
    text-transform: uppercase;
    letter-spacing: -0.02em;
  }

  h2 {
    font-family: $font-default;
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 0;
  }

  h3 {
    margin-top: 0;
    font-family: $font-default;
    font-weight: 600;
    font-size: 1rem;
    text-align: center;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.petImage {
  border-radius: 50%;
  width: 3.75em;
  height: 3.75em;
  overflow: hidden;
  margin-bottom: 1em;
}

.primaryHeader {
  h2 {
    font-family: $font-title;
    font-size: 1.5rem;
    margin-top: 0;
    margin-bottom: 1rem;
  }
}

.secondaryHeader {
  text-align: center;
  font-family: $font-default;
  line-height: 1.2em;
  font-weight: 500;
}

.creditAmount {
  font-family: $font-default;
  font-weight: 700;
  text-decoration: underline;
}

.offer {
  border: 2px solid #ffffff;
  border-radius: 1.5em;
  text-align: center;
  padding: 2em 1em 1em;
  align-self: stretch;
  position: relative;
  color: white;
}

.offerLabel {
  background-color: $yellow;
  position: absolute;
  top: -1em;
  width: 9em;
  box-sizing: border-box;
  left: calc(50% - 4.5em);
  color: #000000;
  border-radius: 1em;
  font-family: $font-default;
  font-weight: 700;
  font-size: 0.875rem;
  text-align: center;
  padding: 0.5em 1em;
  text-transform: uppercase;
}

.offerHeader {
  font-family: $font-default;
  letter-spacing: -0.02em;

  h1 {
    font-family: $font-default;
    font-size: 2rem;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0.6em;
    text-transform: none;
  }

  h2 {
    font-family: $font-default;
    font-size: 1.875rem;
    font-weight: 700;
    margin: 0;
  }

  .offerDiscountSeparator {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.4em;
  }

  .offerDiscountAmount {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.4em;
    text-transform: uppercase;
  }
}

.offerBody {
  font-family: $font-default;
  font-size: 0.875rem;
  line-height: 1.4em;
}

.offerFooter {
  margin-top: 0.25em;
  font-family: $font-default;
  font-size: 0.6875rem;
  line-height: 1.4em;
  font-weight: 500;
}

.offer.selectable {
  cursor: pointer;

  .offerLabel {
    background-color: #ffffff;
  }

  &.selected {
    border-color: $yellow;

    .offerLabel {
      background-color: $yellow;
    }

    .offerDiscountAmount {
      color: $yellow;
    }
  }
}

.valuePropsSection {
  h2 {
    text-align: left;
    margin-bottom: 32px;
    margin-top: 0;
  }
}

.skinDefault {
  .headerDefault {
    display: block;
  }
}

.skinBlackFriday {
  .fixedBackground {
    background-color: #000000;
    background-image: none;
  }
}

.ctaButton {
  width: 100%;
  background-color: $yellow;
  color: $type-light-primary;
}
