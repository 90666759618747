@import '../../styles/variables';

.surveyQuestionWrapper {
  font-family: $font-default;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #f5f5f2;
  border-radius: 24px;
  padding: 48px 40px;

  @media (max-width: $md-max-width) {
    border: none;
  }

  .question {
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -1%;
    margin-bottom: 22px;
  }

  .option {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -1%;
    color: #292b29;
    border-radius: 16px;
    border: 1px solid #bebebb;
    padding: 32px 24px;
    cursor: pointer;
  }

  .form {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;
    background-color: $bg-light-primary;
    border-radius: 2rem;
    border: 1px solid $gray-400;
    padding-left: 2rem;

    &:hover,
    &.focus {
      border-color: black;
      background-color: white;
      box-shadow: 0 0 0 1px black;
    }

    .input,
    .submit {
      font-family: $font-default;
      background-color: transparent;
      border: none;
      height: 2rem;

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }

    .submit {
      cursor: pointer;
      width: 4rem;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .input {
      cursor: text;
      flex-grow: 1;
      overflow: clip;

      &::placeholder {
        color: $type-light-secondary;
      }
    }
  }
}
