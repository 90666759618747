@import '../../styles/variables';

.endlinkContainer {
  composes: splitContainer from '../../styles/splitContainer.module.scss';
  margin-top: $body-top-padding;
  margin-bottom: $body-bottom-padding;

  @media (max-width: $md-max-width) {
    padding: 10px;
  }

  @media (max-width: $md-max-width) {
    composes: mdStacked from '../../styles/splitContainer.module.scss';
    margin-top: 0;
    gap: 0;
  }
}

.details {
  composes: container from '../../styles/container.module.scss';
  gap: 2em;

  @media (max-width: $md-max-width) {
    gap: 1em;
    padding: 0;
  }

  .heading {
    h1 {
      font-family: $font-title;
      font-weight: 700;
      font-size: 80px;
      line-height: 80px;
      letter-spacing: -0.02em;
      color: #000000;
      text-transform: uppercase;
      opacity: 0.9;
      margin: 0;

      @media (max-width: $md-max-width) {
        font-size: 48px;
        line-height: 54px;
        margin-top: 48px;
        // Force words on separate lines
        word-spacing: 10em;
      }
    }
    h3 {
      font-family: $font-default;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: -0.01em;
      color: #000000;
      margin-top: 8px;
    }
    .description {
      font-family: $font-default;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin: 0;
      padding: 0;
      max-width: 500px;
    }
  }

  h2 {
    font-family: $font-default;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.01em;
    color: #000000;
    margin-bottom: 40px;

    @media (max-width: $md-max-width) {
      margin-bottom: 30px;
    }
  }

  .action {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    gap: 1em;
    margin-top: 0.5em;

    @media (min-width: $md-min-width) {
      flex-direction: row;
      margin-top: 1em;
    }
  }
}

.imageGalleryContainer {
  composes: stickyLeftColumn from '../../styles/splitContainer.module.scss';
  composes: mdFullWidth from '../../styles/container.module.scss';
  max-height: 612px;
  top: 0;
}
