@import '../../styles/variables';

.container {
  font-family: $font-default;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 3em 2.5em;
  background-color: $bg-dark-secondary;
  border-radius: 1.5em;
  gap: 1em;
  flex-grow: 1;
  box-sizing: border-box;

  .codeContainer {
    flex-grow: 1;

    .header {
      font-weight: 600;
      color: $type-dark-secondary;
      margin-bottom: 0.25em;
    }

    .code {
      font-weight: 700;
      color: $type-dark-primary;
      font-size: 1.5rem;
      cursor: pointer;
    }
  }

  .buttonContainer {
    flex-grow: 0;

    .shareButton {
      background-color: $yellow;
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4em;
      font-weight: 600;
      border-radius: 3em;
      padding: 0 2em;
      cursor: pointer;
    }
  }

  @media (max-width: $xs-max-width) {
    padding: 1.5em;
  }
}

@keyframes toast-animation {
  0% {
    opacity: 0;
    top: -5px;
  }

  30%,
  60% {
    opacity: 1;
    top: -10px;
  }

  100% {
    opacity: 0;
    top: -20px;
  }
}

.toast {
  flex-direction: column;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;

  .toastContent {
    padding: 5px 15px;
    background: rgba(0, 0, 0, 0.85);
    line-height: 18px;
    color: white;
    cursor: pointer;
    border-radius: 1em;
    font-size: 1rem;
    opacity: 0;
    display: none;

    &.shown {
      display: block;
      animation-timing-function: ease-in-out;
      animation-name: toast-animation;
      animation-duration: 1s;
      animation-iteration-count: 1;
    }
  }
}

.shareLink {
  font-family: $font-default;
  font-size: 1.5rem;
  color: #000000;
}
