@import '../../../../styles/variables';

.deliveryDetails {
  font-family: $font-default;

  h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: -0.02em;
    color: #000000;
    margin: 60px 0 28px 0;
  }

  input {
    font-family: $font-default;
    width: 100%;
    margin: 24px 0;
    display: block;
    padding: 24px 16px 24px 32px;
    box-sizing: border-box;
    border: 1px solid #bebebb;
    border-radius: 100px;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: -0.01em;
  }
  input::placeholder {
    opacity: 1;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: -0.01em;
  }
}
