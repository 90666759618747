@import '../../../../styles/variables';

.accessoriesGroupModule {
  font-family: $font-default;
  margin-top: 1rem;

  .accessoryGroupSeriesToggles {
    font-family: $font-default;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin-top: 40px;

    @media (max-width: $sm-max-width) {
      margin-left: 24px;
    }
  }

  .accessoriesGroupModuleHeader {
    margin-bottom: 80px;

    @media (max-width: $sm-max-width) {
      margin-left: 24px;
      margin-bottom: 64px;
    }

    .accessoriesGroupModuleHeaderTitle,
    .accessoriesGroupModuleHeaderSubtitle {
      font-style: normal;
      font-size: 32px;
      line-height: 38px;
      letter-spacing: -0.01em;
      color: #000000;
    }
    .accessoriesGroupModuleHeaderTitle {
      font-weight: 700;
    }
    .accessoriesGroupModuleHeaderSubtitle {
      font-weight: 400;
      margin-top: 4px;
    }
    .accessoriesGroupModuleHeaderLink {
      margin-top: 24px;

      a {
        color: #000000;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.01em;
        text-decoration-line: underline;
        line-height: 19px;
      }
    }
  }

  .accessoriesGroupModuleItems {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 56px;
    margin-top: 55px;

    @media (max-width: $sm-max-width) {
      display: block;
    }
  }
}
