@import '../../../../styles/variables';

.deliveryDateInputContainer {
  h3 {
    font-family: $font-default;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.02em;
    color: #000000;
    font-weight: 600;
    margin: 12px 0;
  }
  .deliveryDateSelector {
    display: flex;

    @media (max-width: 750px) {
      display: block;
    }

    select {
      margin: 0 1em;
      width: 33%;
      border-radius: 100px;
      font-family: $font-default;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      box-sizing: border-box;
      padding: 24px 16px 24px 32px;
      border: 1px solid #bebebb;
      -moz-appearance: none;
      -webkit-appearance: none;

      @media (max-width: 750px) {
        width: 100%;
        margin: 10px 0;
      }
    }
    select:first-of-type {
      margin: 0;
    }
    select:last-of-type {
      margin: 0;
    }

    select:disabled {
      display: none;
    }
  }
}
