@import '../../styles/variables';

.giftCardView {
  composes: splitContainer from '../../styles/splitContainer.module.scss';
  margin-top: $body-top-padding;
  margin-bottom: $body-bottom-padding;

  @media (max-width: $md-max-width) {
    composes: mdStacked from '../../styles/splitContainer.module.scss';
    margin-top: 0;
    gap: 3em;
  }

  h1 {
    font-family: $font-title;
    font-weight: 700;
    font-size: 80px;
    line-height: 80px;
    letter-spacing: -0.02em;
    color: #000000;
    margin: 0 0 3px 0;

    @media (max-width: 750px) {
      font-size: 48px;
    }
  }
  p {
    font-family: $font-default;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.02em;
    color: #000000;
    max-width: 390px;
    margin: 8px 0;
    text-align: left;
  }
  p.finePrint {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #737373;
    margin-bottom: 4em;
  }

  .giftCardChooser {
    padding: 5px 2px;
    .giftCardChooserTitle {
      font-family: $font-default;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: #737373;
    }
    .giftCardChooserDenomination {
      font-family: $font-default;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.01em;
      color: #000000;
    }
    .giftCardChooserDenomination:before {
      content: '$';
    }
    .giftCardChooserDescription {
      font-family: $font-default;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.01em;
      color: #737373;
    }
  }

  .continueButton {
    width: 100%;
    margin-top: 70px;
  }
  .continueButton:disabled {
  }
}

.imageCarousel {
  composes: stickyLeftColumn from '../../styles/splitContainer.module.scss';
  composes: mdFullWidth from '../../styles/container.module.scss';
}

.giftCardDetails {
  composes: container from '../../styles/container.module.scss';
}
