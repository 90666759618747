@import '../../styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.featuredImageContainer {
  max-width: $md-max-width;
  width: 100%;
  aspect-ratio: 4 / 3;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0 auto;
  gap: 0;
  border-radius: 1.5em;

  &.grayBackground {
    background-color: $light-gray;
  }

  .featuredImageCarousel {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  picture {
    width: 100%;
    height: 100%;
  }

  picture img {
    border-radius: 1.5em;
    mix-blend-mode: multiply;
    width: 100%;
    height: 100%;
    object-fit: contain;
    overflow: hidden;
  }

  &.multipleImages {
    picture {
      margin-right: 64px;
    }
  }

  &.squareAspectRatio {
    aspect-ratio: 1 / 1;
  }

  @media (max-width: $md-max-width) {
    picture img {
      height: auto;
      aspect-ratio: 4/3;
    }

    &.multipleImages {
      picture {
        width: calc(100% - 3em);
        margin-right: 0.75em;
      }
    }

    &.squareAspectRatio {
      &.multipleImages {
        // Adjust for images being shrunk for peek on the sides
        margin-bottom: -3em;
      }

      picture img {
        aspect-ratio: 1 / 1;
      }
    }
  }

  :global(.flickity-page-dots) {
    display: none;
  }
}

.morePhotos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  flex-wrap: nowrap;
  gap: 1em;

  .galleryPhoto {
    cursor: pointer;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;

    border-color: rgba($color: #000000, $alpha: 0.1);
    border-style: solid;
    border-width: 1px;

    background-color: transparent;

    width: 100%;
    height: 100%;
    max-width: 80px;
    max-height: 80px;
    aspect-ratio: 1/1;
    border-radius: 16px;

    picture img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.selected {
      border-color: #000000;
    }

    &.padGallerySelectorImages {
      padding: 10px;
    }
  }

  @media (max-width: $xs-max-width) {
    display: none;
  }

  &.showMobileGallerySelector {
    @media (max-width: $xs-max-width) {
      display: flex;
    }
  }

  @media (max-width: $md-max-width) {
    padding: 0 1.5em;
  }

  @media (min-width: $sm-min-width) and (max-width: $sm-max-width),
    (min-width: $lg-min-width) and (max-width: $lg-max-width) {
    gap: 1em;

    .galleryPhoto {
      max-width: 60px;
      max-height: 60px;
      aspect-ratio: 1/1;
      border-radius: 12px;
    }
  }
}

@media (max-width: $xs-max-width) {
  .featuredImageContainer {
    :global(.flickity-page-dots) {
      display: flex;
      flex-direction: row;
      width: auto;
      align-self: center;
      background-color: $bg-light-secondary;
      padding: 0.5em;
      bottom: -0.75em;
      box-sizing: border-box;
      border-radius: 1em;
      gap: 0.5em;
      align-items: center;

      :global(.dot) {
        margin: 0;
        width: 6px;
        height: 6px;
      }

      :global(.dot.is-selected) {
        width: 8px;
        height: 8px;
      }
    }
  }
}

:global(.ecom-flow-revamp) .wrapper {
  height: 100%;
}

:global(.ecom-flow-revamp) .featuredImageContainer {
  height: 350px;
}

:global(.ecom-flow-revamp) .featuredImageContainer img {
  height: 350px;
  object-fit: cover;
}

@media (min-width: 500px) {
  :global(.ecom-flow-revamp) .featuredImageContainer {
    height: 500px;
  }

  :global(.ecom-flow-revamp) .featuredImageContainer img {
    height: 500px;
    object-fit: cover;
  }
}

:global(.ecom-flow-revamp) picture {
  width: 100%;
  margin-right: none;
}

:global(.supplements-ecom-pdp) picture {
  width: unset;
}

@media (min-width: 1025px) {
  :global(.ecom-flow-revamp) .featuredImageContainer {
    height: unset;
    max-width: unset;
  }

  :global(.ecom-flow-revamp) .featuredImageContainer img {
    height: 100vh;
    object-fit: cover;
  }

  :global(.ecom-flow-revamp) :global(.flickity-viewport) {
    pointer-events: none;
    height: 100vh;
  }

  div:has(:global(.promotionBanner)) :global(.ecom-flow-revamp) .featuredImageContainer img {
    height: calc(100vh - 76px);
  }

  div:has(:global(.promotionBanner)) :global(.ecom-flow-revamp) :global(.flickity-viewport) {
    height: calc(100vh - 76px);
  }

  :global(.ecom-gift-fi) .featuredImageContainer img,
  :global(.ecom-gift-fi) :global(.flickity-viewport) {
    height: calc(100vh);
  }
}

:global(.ecom-gift-fi) :global(.flickity-viewport) {
  background-color: white;
}

:global(.ecom-flow-revamp) :global(.flickity-page-dots) li {
  background: black;
  opacity: 0.16;
}

:global(.ecom-flow-revamp) :global(.flickity-page-dots) :global(.is-selected) {
  opacity: 1;
}

:global(.ecom-flow-revamp-light) :global(.flickity-page-dots) li {
  background: white;
  opacity: 0.4;
}

:global(.ecom-flow-revamp-light) :global(.flickity-page-dots) :global(.is-selected) {
  opacity: 1;
}

:global(.ecom-flow-revamp) :global(.flickity-enabled) {
  position: relative;
}

:global(.ecom-flow-revamp) :global(.flickity-page-dots) {
  display: flex;
  position: absolute;
  bottom: 26px;
  background-color: unset;
  justify-content: center;

  @media (min-width: 1025px) {
    display: none;
  }
}

:global(.ecom-flow-revamp) :global(.flickity-slider) picture {
  width: 100%;
  max-height: 350px;

  @media (min-width: 500px) {
    max-height: 500px;
  }

  @media (min-width: 1025px) {
    max-height: 1vh;
  }
}

:global(.ecom-flow-revamp) :global(.flickity-slider) picture img {
  border-radius: 0;
}

:global(.ecom-flow-revamp) .morePhotos {
  position: absolute;
  bottom: 0px;
  margin-bottom: 22px;
  width: 100%;

  @media (max-width: 1025px) {
    display: none;
  }
}

:global(.supplements-ecom-pdp) .morePhotos {
  top: calc(100vh - 100px);
}

:global(.ecom-flow-revamp) .galleryPhoto {
  max-height: 64px;
  max-width: 64px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.04);
  border-width: 0px;
}

:global(.ecom-flow-revamp) .galleryPhoto:not(.selected)::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: radial-gradient(68.5% 68.5% at 50% 50%, #fff 51%, #f3f3f2 100%);
}

:global(.ecom-flow-revamp) .galleryPhoto:not(.selected):hover::before {
  opacity: 0;
}

:global(.ecom-flow-revamp) .galleryPhoto.selected {
  border-color: #ffffff;
  border-width: 4px;
}

:global(.supplements-pdp) .morePhotos {
  display: none;
}

:global(.supplements-pdp) :global(.flickity-page-dots) li {
  background: white;
  opacity: 0.4;
}

:global(.supplements-pdp) :global(.flickity-page-dots) :global(.is-selected) {
  opacity: 1;
}

:global(.supplements-pdp) :global(.dot) {
  width: 10px !important;
  height: 10px !important;
}
