@import '../../../styles/mixins';
@import '../../../styles/variables';

.main {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  @include webview-full-height();
  font-family: $font-default;
  width: 100%;

  .checkIcon {
    margin: 40px 0;
    width: 100%;
    text-align: center;
    svg {
      background-color: $yellow;
      padding: 33.75px 31.25px;
      border-radius: 80px;
    }
  }

  h1 {
    font-family: $font-title;
    font-size: 56px;
    font-weight: 700;
    letter-spacing: -0.02em;
    margin-bottom: 32px;
    text-align: center;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 71px;
    text-align: center;
    padding: 0 28px;
  }
}
