@import 'variables';

.lineItem {
  composes: celledSection from 'celledSection.module.scss';
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 2em 1.5em;
  gap: 1.25em;
  align-items: stretch;
  margin-bottom: 0.25em;
  border-radius: 0;
  line-height: 1.25em;
  min-height: 180px;

  &.noRemove {
    min-height: auto;
  }

  > * {
    align-items: stretch;
  }

  @media (min-width: $md-min-width) and (max-width: $md-max-width), (min-width: $xl-min-width) {
    padding: 2em;
  }
}

.itemRow {
  composes: splitContainer from 'splitContainer.module.scss';
  gap: 1.25em;
  flex-grow: 1;

  @media (max-width: $xs-max-width) {
    gap: 1em;
  }
}

.lineItemImage {
  flex-grow: 1;
  border-radius: 1em;
  overflow: hidden;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  &,
  img {
    max-width: 64px;
    height: 64px;
  }

  @media (min-width: $md-min-width) and (max-width: $md-max-width), (min-width: $xl-min-width) {
    &,
    img {
      max-width: 80px;
      height: 80px;
    }
  }

  img {
    object-fit: contain;
  }

  &.empty {
    background-color: transparent;
  }
}

.productName {
  line-height: 1.4em;
  font-weight: 600;
}

.variantDescription {
  color: $type-light-secondary;
  font-weight: 400;
}

.secondaryVariantDescription {
  color: $type-light-secondary;
  font-weight: 400;
  font-size: 12px;
}

.lineItemBody {
  margin-left: 0;
  margin-right: 0;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  padding-top: 0.25em;
  gap: 0.5em;
  align-items: stretch;
  justify-content: space-between;
}

.lineItemPriceRows {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-size: 1rem;
  gap: 0.25em;
}

.lineItemPriceRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1em;
  margin-top: 0.75em;

  &:first-child {
    margin-top: 0;
  }
}

.lineItemPrice {
  text-align: right;
  line-height: 1.4em;
  white-space: nowrap;

  &.credit {
    color: $green-200;
  }

  del {
    color: rgba(0, 0, 0, 0.33);
    font-weight: 300;
    text-decoration: none;
    position: relative;

    &::after {
      content: '';
      border-bottom: 2px solid #2cb355;
      position: absolute;
      left: -2px;
      right: -2px;
      margin-top: calc(0.125em / 2 * -1);
      top: 50%;
    }
  }
}

.secondaryPriceRow {
  margin-top: 0;

  .productName,
  .lineItemPrice {
    color: $type-dark-tertiary;
    font-weight: 500;
  }
}

.lineItemQuantity {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.quantityTitle {
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.quantityContainer {
  display: flex;
  justify-content: center;
}

.removeButtonRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;

  .removeButton,
  a {
    color: #737373;
    cursor: pointer;
    font-weight: 500;
  }
}

.giftCardDeliveryInformation {
  font-weight: 400;
  display: flex;
  flex-direction: column;
  line-height: 1.4em;

  .giftMessage {
    white-space: pre-wrap;
  }
}

.freeTrial {
  color: $type-dark-tertiary;
  font-weight: 500;
  text-align: right;

  .lineItemPrice {
    text-decoration: line-through;
  }
}

.lineItemDetailsRow {
  display: flex;
  flex-direction: column;
  gap: 0;

  @media (min-width: $md-min-width) and (max-width: $md-max-width), (min-width: $xl-min-width) {
    flex-direction: row;
    gap: 0.5em;
  }
}

.lineItemOriginalPrice {
  text-decoration: line-through;
}

.waived {
  .productName {
    text-decoration: line-through;
  }

  .waivedLabel {
    line-height: 1.4em;
    font-weight: 600;
    color: $green-200;
    text-decoration: none !important;
  }
}

.addGiftPurchase,
.removeGiftPurchase {
  display: flex;
  padding: 4px 0px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  width: fit-content;
  cursor: pointer;

  font-family: $font-default;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  color: #737373;
  svg * {
    fill: #737373;
  }
}

.removeGiftPurchase {
  color: #07a157;
  svg * {
    fill: #07a157;
  }
}

.removeGiftPurchaseDisabled {
  cursor: unset;
}

:global(.coupon-line-item-v2) {
  flex-direction: row-reverse;
  padding: 24px;
  min-height: unset;
}

:global(.coupon-line-item-v2) .lineItemImage {
  display: none;
}

:global(.coupon-line-item-v2) .removeButtonRow {
  justify-content: flex-start;
  align-self: flex-start;
}

:global(.coupon-line-item-v2) .lineItemPromoContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

:global(.coupon-line-item-v2) .lineItemPrice {
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: left;

  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

:global(.coupon-line-item-v2) .lineItemPromoApplied {
  align-self: flex-start;
  background-color: #fffa01;

  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

:global(.coupon-line-item-v2) .lineItemPrice svg {
  height: 20px;
  width: 20px;
}
