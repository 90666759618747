@import '../../../../styles/variables';

.main {
  margin-bottom: 2em;

  h4 {
    font-family: $font-default;
    font-weight: 600;
    font-size: 1.5rem;
  }
}

.shippingDisclaimer {
  line-height: 1.4em;
}

.shippingOptionsItem {
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.shippingMethod {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.shippingCarrier {
  font-weight: 400;
  color: #737373;
}

.price {
  color: #737373;
  font-weight: 500;
}

@media (max-width: $xs-max-width) {
  .shippingOptionsItem {
    flex-direction: column;
    align-items: flex-start;
  }

  .shippingMethod {
    gap: 0;
    flex-direction: column;
    align-items: flex-start;
  }
}
