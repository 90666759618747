@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.supplementProductSelector {
  width: 30em;
  margin: 1em 0;
  position: relative;
  flex-direction: column;

  ul {
    list-style-type: none;
    padding: 0; // Remove default padding
  }

  @media (max-width: $lg-min-width) {
    width: initial;
  }
}

.allProducts {
  border: 2px solid $bg-light-secondary;
  box-shadow: 0 2px 5px 2px $gray-100;
  border-radius: 1.5em;
  background-color: $bg-light-primary;
  margin-top: 5px;
  li {
    list-style-type: none;
    // First and last children don't benefit from the empty space providing by
    // the horizontal line, so need a bit of additional padding.
    &:first-child {
      padding-top: 1em;
    }
    &:last-child {
      .divider {
        // Don't include bottom border on last product element
        display: none;
      }
    }
  }
}

.check {
  width: 34px;
  height: 34px;
}

.radio {
  border-radius: 100%;
  border: 2px solid $black-70;
  width: 30px;
  height: 30px;
}

.recommended {
  width: 200%; // Allow shipment info text to overflow only in selected product view
  @media (max-width: $sm-min-width) {
    font-size: 11px;
  }
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bottom {
  width: 100%;
}

.product {
  height: 8em;
  margin-bottom: 2em;
  display: flex;
  justify-content: space-between;
  font-family: $font-default;
  border-radius: 1.5em;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  padding: 1.2em;
  .left {
    padding-right: 10%;
  }
}

.left {
  margin-right: 7em;
  @media (max-width: $lg-min-width) {
    margin-right: 5em;
  }

  div {
    // Put vertical space between elements
    margin: 0.3em 0;
  }
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  svg {
    margin-top: 1.5em;
  }
}

.size {
  display: inline;
  border: 1px solid $black-20;
  border-radius: 48px;
  padding: 3px 6px;
  font-weight: 700;
  font-size: 12px;
}

.shippingCadence {
  font-size: 24px;
  font-weight: 700;
  width: 200%;
  @media (max-width: $lg-min-width) {
    font-size: 18px;
  }
}

.billingCadence {
  font-size: 14px;
  color: $type-light-secondary;
  .originalPrice {
    text-decoration-line: line-through;
    text-decoration-color: $type-light-secondary;
    text-decoration-thickness: 1px;
    -webkit-text-decoration-line: line-through;
    -webkit-text-decoration-color: $type-light-secondary;
    -webkit-text-decoration-thickness: 1px;
  }
}

.price {
  font-size: 1.2em;
  @media (max-width: $sm-min-width) {
    font-size: 1em;
  }
}

.freeLabel {
  border-radius: 48px;
  background-color: $collar-yellow;
  display: inline;
  padding: 0.5em 1em;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1.12px;
  font-family: $font-title;
  position: relative;
  top: 10px;
}

.desktopPlanSelection {
  display: none;

  .divider {
    border: 0.5px solid $gray-100;
    margin: 1.2em 0;
  }
  @media (min-width: $lg-min-width) {
    display: block;
  }
}

.mobilePlanSelection {
  display: block;
  margin-bottom: 0.75em;
  .divider {
    border: 0.5px solid $gray-100;
    margin: 0.7em 0;
  }

  @media (min-width: $lg-min-width) {
    display: none;
  }
}
