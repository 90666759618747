@import '../../../../styles/variables';

.inviteList {
  font-family: $font-default;

  h3 {
    font-size: 24px;
    line-height: 140%;
    font-weight: 600;
    letter-spacing: -0.01em;
    border-bottom: 1px solid #bebebb;
    padding-bottom: 24px;
    text-align: left;
    margin-bottom: 0;
  }

  ol {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 120%;
      letter-spacing: -0.01em;
      border-bottom: 1px solid #bebebb;
      padding: 14px 0;

      .inviteImage {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        margin-right: 16px;

        img {
          width: 40px;
          height: 40px;
          border-radius: 100%;
        }
      }

      .inviteIndex {
        display: flex;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: #e0e0dd;
        margin-right: 16px;

        p {
          text-align: center;
          margin: auto;
          color: #000000;
        }
      }

      .shareButton {
        color: #0065f1;
        display: flex;
        flex-direction: row;
        align-items: center;
        p {
          margin-left: 6px;
        }
      }
    }
  }
}
