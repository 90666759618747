@import 'variables';

.variant {
  font-family: $font-default;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
}

.variantTitleGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1rem;
  gap: 0.5em;
  margin-bottom: 1.5em;

  @media (min-width: $md-max-width) {
    margin-bottom: 2em;
  }

  .selectedVariantLabel {
    font-family: $font-default;
    font-size: 1rem;
    font-weight: 500;
  }
}

:global(.ecom-nano-variant) .variantTitleGroup {
  margin-bottom: 8px;
}

.variantTitle {
  font-family: $font-default;
  font-size: 1.5rem;
  font-weight: 600;
}

.variantSubtitle {
  font-family: $font-default;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: #737373;
}

.variantTitleError {
  color: red;
}

.variantInfo {
  color: $type-dark-tertiary;
}

.roundVariantSelector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}

.moreInfo {
  button {
    font-family: $font-default;
    text-align: left;
    padding: 0;
    margin-top: 0;
    color: #000;
    text-decoration: underline;
    font-size: 1rem;
    text-transform: none;
    font-weight: 400;
    background: none;
    border: none;
    cursor: pointer;
  }
}
