@import '../../../../styles/variables';

.container {
  composes: container from '../../../../styles/container.module.scss';
}

.addressConfirmationModal {
  font-family: $font-default;
  padding: 2em;

  h3 {
    font-family: $font-title;
    font-size: 2rem;
    margin: 0;
    line-height: 1em;
  }

  p {
    margin-top: 1em;
    margin-bottom: 2em;
    line-height: 1.4em;
    text-align: left;
  }

  label {
    font-size: 1.25rem;
  }
}

:global(.supplements-address-confirmation-modal) {
  h3 {
    font-family: $font-default;
  }
}

ul.addressList {
  list-style-type: none;
  padding-left: 0;
  margin-left: 0;
  margin-bottom: 3em;

  li {
    margin-bottom: 2em;

    &:last-child {
      margin-bottom: 0.5em;
    }

    .addressItem {
      display: flex;
      flex-wrap: wrap;
      gap: 1em;

      label {
        flex-grow: 1;
      }

      .cta {
        flex-grow: 0;
        visibility: visible;

        &.invisible {
          visibility: hidden;
        }
      }
    }
  }

  h4 {
    margin-bottom: 0.5em;
    font-weight: 700;
  }

  .checkbox {
    display: flex;
    flex-direction: row;
  }
}

.selectAddressButton {
  margin-top: 0;
  padding-left: 2em;
  padding-right: 2em;
}

.form {
  composes: form from '../../../../styles/form.module.scss';
}

.formSection {
  composes: formSection from '../../../../styles/form.module.scss';
}

.formRow {
  composes: formRow from '../../../../styles/form.module.scss';
}

.loginButton {
  margin-top: 1em;
  text-align: left;

  @media (min-width: $md-min-width) {
    text-align: center;
  }
}

.phoneNumberDescription {
  display: flex;
  padding: 0px 24px;
  align-items: flex-end;
  gap: 10px;
  align-self: stretch;

  font-family: $font-default;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.14px;
  color: #9e9e9c;
}
