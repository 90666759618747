@import '../../styles/variables';

.productContainer {
  composes: splitContainer from '../../styles/splitContainer.module.scss';
  margin-top: $body-top-padding;
  margin-bottom: $body-bottom-padding;

  & > * {
    max-width: 660px;
  }

  @media (max-width: $md-max-width) {
    composes: mdStacked from '../../styles/splitContainer.module.scss';
    margin-top: 0px;
    gap: 3em;
    align-items: center;
  }
}

.imageGalleryContainer {
  composes: stickyLeftColumn from '../../styles/splitContainer.module.scss';
  composes: mdFullWidth from '../../styles/container.module.scss';
}

.desktopImageGallery {
  display: none;

  @media (min-width: $lg-min-width) {
    display: block;
  }
}

.mobileImageGallery {
  display: block;
  margin-bottom: 0.75em;

  @media (min-width: $lg-min-width) {
    display: none;
  }
}

.productDetails {
  composes: container from '../../styles/container.module.scss';
  gap: 5em;

  @media (min-width: $lg-min-width) {
    gap: 4em;
  }

  .mobileImageGallery {
    width: calc(100% + $mobile-padding * 2);
    margin-top: -1.5em;
    margin-left: -$mobile-padding;
  }
}

.productDetailsHeader {
  display: flex;
  flex-direction: column;
  gap: 1em;

  .productDetailsTitle {
    margin-bottom: 1em;
  }
}

.productTitleContainer {
  margin-left: 24px;
  margin-right: 24px;
}

.productTitleContainer .productTitle {
  font-family: $font-default;
  font-size: 44px;
  font-weight: 600;
  line-height: 100%;
  margin-bottom: 6px;
  letter-spacing: -1.76px;
}

.productTitleContainer .productTitleDescription {
  font-family: $font-default;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  color: #737373;
}

.description {
  text-align: left;
  line-height: 1.4em;
  margin: 0;
  padding: 0;
  max-width: 500px;
  white-space: pre-wrap;
}

.styleOptions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 2em;

  @media (min-width: $lg-min-width) {
    flex-direction: row;
    gap: 1em;
  }
}

.variantOptions {
  display: flex;
  flex-direction: column;
  gap: 2em;
  margin: 0;
  padding: 0;
}

.productAction {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 2em;
  margin-top: -1.5em;

  @media (min-width: $lg-min-width) {
    gap: 3em;
    margin-top: 0;
  }
}

.seriesToggle {
  composes: variant from '../../styles/variants.module.scss';
}

.seriesToggleTitleGroup {
  composes: variantTitleGroup from '../../styles/variants.module.scss';
}

.seriesToggleTitle {
  composes: variantTitle from '../../styles/variants.module.scss';
}

.fiMakersLink {
  font-weight: 600;
  font-family: $font-default;
  text-decoration: underline;
  font-size: 1em;
  color: #000000;
}

.sectionHeader {
  font-family: $font-default;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.33em;
}

.subSectionHeader {
  font-family: $font-default;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 2em;
}

.valueProp {
  font-family: $font-default;
  font-size: 1em;
  color: #000000;
}

.valuePropHeader {
  font-weight: 600;
}

.valuePropBody {
  font-weight: 500;
}

// The design has the text extending past the content box in some cases to avoid wrapping too many lines.
.valuePropCheat {
  margin-right: -1em;
}

.footerValueProps {
  font-size: 1rem;
  font-weight: 600;
  color: $type-light-secondary;
  text-align: center;
}

.valuePropBadge {
  padding: 0.1em 0.3em;
  border-radius: 0.3em;
  color: $blue-100;
  border: 1px solid $blue-100;
  margin-right: 0.2em;
}

.productContainer.ecomFlowRevamp {
  margin-top: 0px;
  margin-bottom: 0px;
  gap: 0px;
  max-width: unset;
  & > * {
    max-width: unset;
  }
}

@media (max-width: $xs-max-width) {
  h2 {
    font-size: 18px;
  }
}

@media (max-width: $md-max-width) {
  .productTitleContainer .productTitle {
    font-size: 32px;
  }
  .productTitleContainer .productTitleDescription {
    font-size: 18px;
  }
}

@media (max-width: 375) {
  .productTitleContainer .productTitleDescription {
    font-size: 15px;
  }
}

:global(.ecom-flow-revamp) * {
  -webkit-text-size-adjust: 100%;
}

:global(.ecom-flow-revamp) .productDetails {
  padding: 0;
  gap: 0;
  @media (min-width: 1025px) {
    max-width: 500px;
  }
  @media (min-width: 1820px) {
    max-width: calc(100% - 1320px);
  }
}

:global(.ecom-flow-revamp) .galleryContainer {
  @media (min-width: 1820px) {
    max-width: 1320px;
  }
}

body:has(:global(.ecom-flow-revamp)):has(:global(.nav-bar-open)),
html:has(:global(.ecom-flow-revamp)):has(:global(.nav-bar-open)) {
  @media (max-width: 1024px) {
    overflow: hidden;
  }
}

:global(.ecom-flow-revamp) .productTitleContainer {
  padding-top: 28px;
}

:global(.ecom-flow-revamp) .galleryContainer {
  position: relative;
  width: 100%;
}

:global(.ecom-flow-revamp) .valuePropsSection {
  margin: 0px 24px 30px 24px;
}

.inAppManagement .valuePropsSection {
  margin: 1em 0 0 0;
}

:global(.ecom-flow-revamp) .subSectionHeader {
  font-family: $font-default;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  margin: 0;
}

:global(.ecom-flow-revamp) .valuePropHeader {
  font-weight: 500;
}

:global(.ecom-flow-revamp) .valuePropHeaderBold {
  font-weight: 600;
}

:global(.ecom-flow-revamp) .multipleDogsHeader {
  font-family: $font-default;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  margin-top: 16px;
}

:global(.ecom-flow-revamp) .multipleDogsDescription {
  font-family: $font-default;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  color: #737373;
}

:global(.ecom-flow-revamp) footer {
  margin-bottom: 40px;
}

.variantOptions {
  display: flex;
  flex-direction: column;
  gap: 2em;
  margin: 0;
  padding: 0;
}

:global(.ecom-flow-revamp) .variantOptions {
  margin: 0px 24px 32px 24px;
}

.disabledSection {
  color: #999;
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
}
