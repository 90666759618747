@import '../../../../styles/variables';

.variantOptions {
  display: flex;
  flex-direction: column;
  gap: 2em;
  margin: 0;
  padding: 0;
}

:global(.ecom-flow-revamp) .variantOptions {
  margin: 0px 24px 32px 24px;
}

.fiMakersLink {
  font-weight: 600;
  font-family: $font-default;
  text-decoration: underline;
  font-size: 1em;
  color: #000000;
}
