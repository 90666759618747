@import '../../styles/mixins';
@import '../../styles/variables';

.container {
  @include webview-full-height();

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
}

.section {
  padding: 0 24px;
  width: 100%;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &.billing {
    padding: 0;
  }
}

.renewalNotice {
  display: flex;
  flex-direction: column;
  gap: 0;
  text-align: center;
  font-family: $font-default;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: $type-light-secondary;

  p {
    margin: 0;
  }
}

.confirmationContainer {
  @include webview-full-height();

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;

  .section {
    justify-content: center;
  }

  .checkIcon {
    width: 100%;
    text-align: center;
    svg {
      width: 100%;
    }
  }

  h2 {
    font-family: $font-title;
    font-size: 2.5em;
    font-weight: 700;
    letter-spacing: -0.02em;
    text-align: center;
    margin-top: 0.5em;
    margin-bottom: 0;
  }

  p {
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    margin-top: 0.25em;
    text-align: center;
    padding: 0;
    color: $type-light-secondary;
  }
}

.actions {
  box-sizing: border-box;
  padding: 1em 1.5em;
  width: 100%;

  button {
    width: 100%;
  }

  .buttonSpacer {
    height: 1em;
  }
}

.subscriptionInfoCard {
  background: #f5f5f5;
  border-radius: 12px;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1em;
  margin-bottom: 0.5em;
}

.subscriptionLabel {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 4px;
}

.subscriptionInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 4px;
}

.subscriptionRow {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  line-height: 140%;
  height: 22px;

  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
  }

  .price {
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
    text-decoration: line-through;
  }
}

.subscriptionSubtext {
  font-size: 12px;
  color: $type-light-secondary;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.dueRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e5e5e4;
  padding-top: 16px;
  width: 100%;

  .label {
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
  }

  .amount {
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
  }
}
