@import '../../../../../styles/variables';

.orderConfirmedBanner {
  background-color: $type-light-primary;
  padding: 1em;
  color: $type-dark-primary;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: row;
  gap: 16px;
  border-radius: 12px;
  margin: 24px 24px 0px 24px;
  svg {
    min-width: 30px;
    min-height: 30px;
    max-width: 40px;
    max-height: 40px;
  }

  .text {
    margin: 5px 0;
  }

  .confirmedText {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    letter-spacing: -0.01em;
    text-align: left;
    margin-bottom: 4px;

    svg {
      margin-right: 0.5em;
      min-width: 10px;
      min-height: 10px;
      > path {
        stroke: $yellow;
      }
    }
    span {
      vertical-align: super;
    }
  }

  .emailText {
    opacity: 0.75;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.4px;
    text-align: left;
    margin: 0;
  }
}

@media (max-width: $sm-min-width) {
  .orderConfirmedBanner {
    text-align: left;
    .text {
      margin: 0 0.5em;
    }
    .confirmedText {
      font-size: 14px;
    }
    .emailText {
      line-height: 150%;
      margin: 0.3em 0 0;
      letter-spacing: -0.12px;
    }
  }
}
