@import '../../styles/variables.scss';

.centeredContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: $bg-light-secondary;
  cursor: pointer;
}

.content {
  text-align: center;
}

.reloadIcon {
  width: 50px;
  height: 50px;
  fill: $type-light-secondary;
}

.text {
  font-size: 1rem;
  margin-top: 1em;
}

.retry {
  margin-top: 0.5em;
  text-decoration: underline;
}
