@import '../../../../styles/variables';

.container {
  width: 100%;
  box-sizing: border-box;
  height: calc(2 * ($app-bar-height-mobile + env(safe-area-inset-top)));
  line-height: calc(($app-bar-height-mobile + env(safe-area-inset-top)));
  top: calc(
    -1 * ($app-bar-height-mobile + env(safe-area-inset-top))
  ); // Extend element above top of screen to cover any content that might pass behind
  font-size: 1rem;
  position: fixed;
  z-index: 2;
  background-color: $bg-light-primary;
  transform: translate3d(0, 0, 0);

  &.darkBackground {
    background-color: #f3f3f2;
  }
}

.subAppBarContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5em 0 0 0; // Force app bar items down within the viewport.
  z-index: 3; // App bar items should stay at forefront of background.

  .button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3em;
    height: 3em;
    position: relative;
  }

  .hiddenBackButton {
    visibility: hidden;
    pointer-events: none;
  }

  // Make larger surface area clickable without moving icons.
  .button:after {
    content: '';
    position: absolute;
    top: -15px;
    bottom: -15px;
    left: -15px;
    right: -15px;
  }

  .title {
    font-weight: 600;
  }
}

.hiddenShoppingBag {
  visibility: hidden;
  pointer-events: none;
}
