.chicletRow {
  display: flex;
  flex-direction: row;
}

.chiclet {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 9999px;

  margin: 0 0.5rem;

  background-color: black;
  opacity: 0.4;

  &.active {
    opacity: 1;
  }
}
