@import '../../../../styles/variables';

.container {
  composes: container from '../../../../styles/container.module.scss';
  padding: 0;
}

.viewBillingInfo {
  line-height: 1.5em;
}

.billingDetails {
  margin-bottom: 2em;
}

.unlinkBillingCta {
  text-decoration: underline;
  cursor: pointer;
}

.creditCardBrand {
  font-weight: 700;
}

.newBillingAddressForm {
  composes: form from '../../../../styles/form.module.scss';
  margin-top: 1em;

  &.compact {
    margin-top: 0;
  }
}

.formSection {
  composes: formSection from '../../../../styles/form.module.scss';
  padding-bottom: 0;
  margin-bottom: 0;
}

.formRow {
  composes: formRow from '../../../../styles/form.module.scss';
}

input.error {
  border-color: $red !important;
}
