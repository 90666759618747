@import '../../../../styles/variables';

.main {
  font-family: $font-default;
  display: flex;
  flex-direction: row;
  align-items: center;

  .petArrow {
    display: none;
  }

  &.clickable {
    cursor: pointer;

    .petArrow {
      display: block;
    }
  }

  img {
    border-radius: 50%;
    object-fit: cover;
    width: 40px;
    height: 40px;
    display: block;
  }

  .petTitle {
    font-size: 24px;
    font-weight: 400;
    flex: 1;
    margin-left: 10px;
  }

  .textHighlight {
    color: $blue;
    font-weight: 200;
  }
}
