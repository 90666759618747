@import '../../styles/variables';

.breadcrumbs {
  font-family: $font-default;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  gap: 2em;

  @media (max-width: $sm-max-width) {
    gap: 1.5em;
    font-size: 0.875rem;
    justify-content: flex-start;
  }
}

.breadcrumb {
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: #737373;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.25em;

  &.disabled {
    cursor: default;
  }

  &.selected {
    color: #000000;
  }
}

.sectionNumber {
  width: 1.5em;
  height: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
