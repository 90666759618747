.rescuePlacementContainer {
  padding: 0 20px;
  margin: 20px auto;
  max-width: 550px;

  p {
    text-align: left;
  }

  h2 {
    font-size: 38px;
  }

  .rescueBase {
    margin-bottom: 5px;
  }

  .checkboxCustom {
    font-size: 1.25rem;
    display: flex;
  }

  .rescuePlacementDisclaimer {
    font-size: 18px;
    opacity: 0.75;
  }
}
