@import '../../styles/variables';

.valuePropList {
  font-family: $font-default;
  display: flex;
  flex-direction: column;
  gap: 1em;

  &.horizontalLayout {
    flex-direction: row;
    gap: 1.75em;

    @media (max-width: 720px) {
      gap: 0.5em;
      flex-direction: column;
    }
  }

  .valuePropListItem {
    display: flex;
    align-items: center;
    gap: 0.25em;
  }
}
