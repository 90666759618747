@import '../../../../../../styles/variables';

.billingInfo {
  margin-top: 6em;
}

.disclaimer {
  font-family: $font-default;
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
  color: #737373;
  text-align: center;
  margin-top: 24px;

  a {
    color: #737373;
    text-decoration: underline;
    line-height: 140%;
  }
}

.purchaseConfirmationContainer {
  margin-top: 100px;
  padding: 0 24px;
}

.purchaseConfirmationCTAContainer {
  display: block !important;
}
