@import '../../../styles/mixins';
@import '../../../styles/variables';

.main {
  @include webview-full-height();
  width: 100%;
  background: #ffffff;
  background-image: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 138.83%),
    url(../../../assets/images/backgrounds/subscription-blocker.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% 103px;
  padding-top: 175px;

  .avatar {
    width: 96px;
    height: 96px;
    position: absolute;
    top: 63px;
    left: 16px;
    background-image: url(../../../assets/images/subscription-blocker-avatar.png);
    background-size: contain;
  }

  .content {
    margin: 0 24px;
    font-family: $font-default;

    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 27px;
      letter-spacing: -0.02em;
      color: #000000;
      margin-bottom: 16px;
    }

    h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.02em;
      color: #000000;
      margin-bottom: 24px;
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      text-align: left;
      margin-bottom: 40px;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 1em;

      li {
        .check {
          margin-right: 10px;
        }
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.02em;
      }
    }
  }
}
