@import '../../styles/variables';

$collision-width: 410px;

.container {
  font-family: $font-default;
  display: flex;
  align-items: stretch;
  align-content: space-between;
  justify-content: center;
  width: 100%;
}

.savingsPill,
.valueLabel {
  background-color: white;
  border: 1px solid #e5e5e4;
  border-radius: 19px;
  padding: 2px 8px 2px 8px;

  font-family: $font-default;
  font-size: 12px;
  line-height: 140%;
  font-weight: 700;
  white-space: nowrap;
}

.couponExtension {
  background-color: $yellow;
  border: none;
  border-radius: 6px;
  padding: 6px 8px 6px 8px;

  display: flex;
  flex-direction: column;
  margin: 0px;

  .title {
    font-family: $font-default;
    font-size: 10px;
    line-height: 120%;
    font-weight: 600;
    color: #000000;
    text-align: right;
    display: inline-block;
    width: 100%;
    margin: 0;
    letter-spacing: 0.3px;
  }

  .description {
    font-family: $font-default;
    font-size: 14px;
    line-height: 140%;
    font-weight: 600;
    color: #000000;
    text-align: right;
    display: inline-block;
    width: 100%;
    margin: 0;
    letter-spacing: -0.14px;
  }
}

.valueLabel {
  color: $type-dark-primary;
  font-family: $font-title;
}

.deactivated {
  background-color: $type-light-primary;
}

.supportPlusWrapper {
  font-size: 0.7rem;
  font-weight: 500;
  margin-top: auto;

  .supportLabel {
    float: right;
    color: #0065f1;
  }

  .supportDescription {
    color: #737373;
  }
}

.billingCadence {
  font-family: $font-default;
  font-weight: 600;
  font-size: 1rem;
  line-height: 140%;
  white-space: nowrap;
}

.price {
  font-family: $font-default;
  font-size: 1rem;
  font-weight: 600;
  line-height: 120%;
}

.priceBold {
  font-family: $font-default;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;

  &Crossed {
    font-size: 16px;
    color: '#737373';
    text-decoration: line-through;
    text-decoration-thickness: 2px; // Adjust thickness
    color: gray;
  }
}

.description {
  font-size: 0.75rem;
  margin: 0;
  font-weight: 500;
  line-height: 140%;
  color: $type-dark-tertiary;
  width: 100%;
  white-space: nowrap;
  color: #737373;
}

.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3px;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.row {
  display: flex;
  gap: 0.5rem;
}

.title {
  align-items: center;
  margin-bottom: 0.5rem;
  @media (max-width: $collision-width) {
    width: 100%;
  }
}

.mostPopularPill {
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  color: white;
  background: #1360f4;

  font-family: $font-default;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;

  margin-top: -64px;
  margin-left: -50px;
}

.container.prominentPricingDesign {
  .billingCadence {
    text-transform: uppercase;
    color: #000000;
    font-family: $font-default;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.14px;
    text-transform: uppercase;
  }

  .description {
    font-size: 0.875rem;
  }

  .couponExtension {
    margin-right: -19px;
    border-radius: 0;
    padding: 0.25rem 0.75rem 0.5rem;
    border-radius: 6px 0 0 6px;
  }

  .monthToMonthPrice {
    font-size: 1rem;
  }

  .minimumTerm {
    color: #737373;
    text-align: right;
    font-family: $font-default;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
  }
}
