@import '../../../../styles/variables';

.petList {
  font-family: $font-default;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1em;
}

.pet {
  background-color: #f5f5f2;
  border-radius: 1.5em;
  padding: 1em;
  display: flex;
  flex-direction: row;
  gap: 0;
}

.petImage {
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  border-radius: 1em;
  overflow: hidden;
  padding: 0;
  margin-right: 1em;

  &.noImage {
    background-color: $yellow;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  picture img {
    object-fit: cover;
    width: 100%;
  }
}

.petDetails {
  align-self: center;
  flex-grow: 1;
  margin-right: 0.25em;
}

.petName {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2em;
  margin-bottom: 0.25em;
}

.petSubtitle {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.2em;
  color: $type-light-secondary;
}

.creditAmount {
  text-decoration: underline;
}

.addIcon {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  border-radius: 50%;
  cursor: pointer;

  svg,
  svg path {
    fill: #ffffff;
  }
}

.petList.darkBackground {
  .pet {
    background-color: rgba(255, 255, 255, 0.24);
    color: #ffffff;
  }

  .petSubtitle {
    color: #ffffff;
  }

  .addIcon {
    background-color: #ffffff;

    svg,
    svg path {
      fill: #000000;
    }
  }
}
