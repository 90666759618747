@import '../../styles/variables';

.container {
  composes: smFullWidth from '../../styles/container.module.scss';
}

.accessoriesModule {
  margin-top: 1rem;

  hr {
    border: 2px solid #f5f5f2;
    margin: 120px 0;
  }

  h2,
  h3,
  h4 {
    font-family: $font-title;
    margin-bottom: 0;
  }
  h2 {
    font-weight: 700;
    font-size: 80px;
    line-height: 1;
    letter-spacing: -0.02em;
    text-transform: uppercase;

    @media (max-width: $sm-max-width) {
      margin-left: 24px;
      font-size: 48px;
      line-height: 100%;
    }
  }
  h3 {
    font-weight: 700;
    size: 32px;
    line-height: 38px;
    letter-spacing: -0.01em;
  }
  h4 {
    font-weight: 400;
    size: 32px;
    line-height: 38px;
    letter-spacing: -0.01em;
  }
  @media (max-width: $sm-max-width) {
    margin-right: auto;
    margin-left: auto;
  }
}

.fiMakersSection {
  scroll-margin-top: $app-bar-height;
}
