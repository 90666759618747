@import '../../../../../styles/variables';

body:has(.container) {
  overflow: hidden;
}

:global(.s3-popup-body):has(.container),
.container button {
  touch-action: manipulation;
}

.container {
  display: flex;
  padding: 40px 24px 24px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: #f3f3f2;
  border-radius: 24px 24px 0px 0px;
  @media (min-width: 769px) {
    border-radius: 24px;
  }
  pointer-events: auto;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.body {
  width: 100%;
}

.closeIcon {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background: #e5e5e4;
  cursor: pointer;
}

.title {
  font-family: $font-default;
  font-size: 32px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.64px;
}

.subtitle {
  margin-top: 24px;
  font-family: $font-default;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.16px;
}

.weightContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.weightContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 32px);
  margin-top: 8px;
  padding: 19px 16px;
  border-radius: 86px;
  border: 1px solid #bebebb;
  background-color: white;
}

.weightMinusButton,
.weightPlusButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: white;
  border: none;
  position: relative;
}

.weightMinusButton::before,
.weightPlusButton::before {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.weightMinusButtonDisabled,
.weightPlusButtonDisabled {
  opacity: 0.25;
  pointer-events: none;
}

.weightValue {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  font-family: $font-default;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
}

.weightValue input {
  display: flex;
  align-items: center;
  text-align: center;

  font-family: $font-default;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  color: black;

  width: 34px;
  border: none;
}

/* Remove the up and down arrows from number input */
.weightValue input[type='number']::-webkit-inner-spin-button,
.weightValue input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.weightValue input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield; /* Firefox */
}

.currentWeightValueUnit {
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: #737373;
}

.frequencyContainer {
  display: flex;
  padding: 20px;
  margin-top: 24px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 24px;
  gap: 16px;
  background: white;
  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.02);
}

.frequencyRecommendation {
  font-family: $font-default;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.48px;
}

.frequencyRecommendationValue {
  color: #07a157;
}

.frequencyRecommendationDetails {
  font-family: $font-default;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
}

.frequencyUpdateButton {
  display: flex;
  padding: 18px 35px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  border-radius: 100px;
  background: black;
  color: white;

  font-family: $font-default;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.16px;
  cursor: pointer;
}
