@import '../../../../../styles/variables';

.imageGalleryContainer {
  composes: stickyLeftColumn from '../../../../../styles/splitContainer.module.scss';
  composes: mdFullWidth from '../../../../../styles/container.module.scss';
}

.desktopImageGallery {
  display: block;
  padding: 0;
  top: 0px;
}

div:has(:global(.promotionBanner)) .desktopImageGallery {
  @media (min-width: 1025px) {
    top: 91px;
    height: calc(100vh - 92px);
  }
}
