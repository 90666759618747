@import '../../../../styles/variables';

@keyframes buy-action {
  0% {
  }
  2% {
    background-color: $yellow;
    border-color: $yellow;
  }
  80% {
    background-color: $yellow;
    border-color: $yellow;
  }
  100% {
  }
}

@keyframes buy-contents {
  0% {
  }
  5% {
    transform: translateY(100%);
  }
  80% {
    transform: translateY(100%);
  }
  100% {
  }
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;

  &[disabled] {
    background-color: #bebebe;
    color: white;
    cursor: default;
  }

  .overflowContainer {
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;

    .buttonContents {
      height: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100%;
      }

      .added {
        display: none;
        font-weight: 600;
        color: #000000;
      }
    }
  }

  &.isBuying {
    animation: buy-action 1.5s ease-in-out 1;

    .overflowContainer {
      .buttonContents {
        animation: buy-contents 1.5s ease-in-out 1;

        .added {
          display: flex;
        }
      }
    }
  }
}
