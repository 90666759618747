@import '../../styles/variables';

.container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: calc($app-bar-height-mobile + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) 0 1em;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #ffffff;

  &.darkBackground {
    background-color: transparent;

    svg,
    svg path {
      fill: #ffffff;
    }
  }
}

.title {
  font-size: 18px;
  font-weight: 600;
  flex: 1;
  text-align: center;
}

.left {
  display: flex;
  justify-content: flex-start;
  width: 4em;
}

.right {
  display: flex;
  justify-content: flex-end;
  width: 4em;
}

.button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
