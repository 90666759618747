$font-default: 'MessinaSans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif;

$font-title: 'MessinaSans-Compact', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif;

$app-bar-height: 96px;
$app-bar-height-mobile: 80px;

$xs-max-width: 480px;
$sm-min-width: 481px;
$sm-max-width: 768px;
$md-min-width: 769px;
$md-max-width: 1024px;
$lg-min-width: 1025px;
$lg-max-width: 1280px;
$xl-min-width: 1281px;
$xl-max-width: 1440px;
$xxl-min-width: 1441px;

$mobile-padding: 1.5em;
$body-top-padding: 3em;
$body-bottom-padding: 3em;

$radius: 24px;

$savings-green: #2cb355;
// Yellow branding color
$yellow: #fff618;
$light-gray: #f5f5f2;
$red: #ff004f;

$gray-100: #e0e0dd;
$gray-200: #f3f3f2;
$gray-300: #e5e5e4;
$gray-400: #bebebb;

$black-70: rgba(
  $color: #000000,
  $alpha: 0.7,
);

$black-50: rgba(
  $color: #000000,
  $alpha: 0.5,
);

$black-30: rgba(
  $color: #000000,
  $alpha: 0.3,
);

$black-20: rgba(
  $color: #000000,
  $alpha: 0.2,
);

$black-10: rgba(
  $color: #000000,
  $alpha: 0.1,
);

$blue: #027eff;
$blue-100: #2da7ff;
$blue-300: #bbddff;
$green-50: #e1faee;
$green-100: #00c729;
$green-200: #0a7e00;
$green-300: #288e20;
$green-400: #07a157;
$green-500: #00773e;

// Light background
$type-light-primary: #000000;
$type-light-secondary: #737373;

$bg-light-primary: #ffffff;
$bg-light-secondary: #f5f5f2;

// Dark background
$type-dark-primary: #ffffff;
$type-dark-secondary: #9e9e9c;
$type-dark-tertiary: #737373;

$bg-dark-primary: #000000;
$bg-dark-secondary: #242424;

// Colors specific to collar products
$collar-yellow: #fffa01;
$collar-blue: #0065f1;
$collar-pink: #dc3793;
$collar-gray: #737373;

$strava-orange: #fc5200;
