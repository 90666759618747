@import '../../../../styles/variables';

.form {
  composes: form from '../../../../styles/form.module.scss';

  .cardOnFileInfo {
    display: flex;
    align-items: center;
    padding: 12px;
    background: #f3f3f2;
    border-radius: 8px;
    margin-bottom: 16px;
    font-size: 12px;
    font-weight: 500;
    color: $type-light-secondary;

    .creditCardIcon {
      margin-right: 4px;
    }

    strong {
      font-weight: 700;
      margin-right: 3px;
      color: $type-light-primary;
    }
  }
}

.formActionContainer {
  padding: 1em 1.25em;
}

.compact {
  composes: compact from '../../../../styles/form.module.scss';

  .formInputContainer {
    flex: 1;
    padding-bottom: 1em;

    @media (min-width: $md-min-width) {
      padding-bottom: 0;
    }
  }

  .formActionContainer {
    border-top: 1px solid #f1f1ef;
    background-color: white;
    position: sticky;
    bottom: 0;

    @media (min-width: $md-min-width) {
      border-top: none;
    }
  }
}

.formSection {
  composes: formSection from '../../../../styles/form.module.scss';
  padding: 0 1.25em;
  padding-bottom: 0;
  margin-bottom: 0;

  @media (min-width: $md-min-width) {
    padding-bottom: 1em;
    margin-bottom: 1em;
  }
}

.formRow {
  composes: formRow from '../../../../styles/form.module.scss';
}
