@import '../../styles/variables';

textarea {
  width: 100%;
  border: 1px solid #bebebb;
  border-radius: 1.5em;
  padding: 24px 16px 24px 32px;
  font-family: $font-default;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.01em;
  box-sizing: border-box;
  font-weight: 600;
  resize: none;
}

.characterLimitRemaining {
  font-weight: 500;
  font-family: $font-default;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.01em;
  color: #737373;
  margin-left: 30px;
  margin-top: 16px;
}
