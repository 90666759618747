@import '../../styles/variables';

.seriesToggleButton {
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  padding: 12px 24px;
  gap: 16px;
  color: #737373;
  border: 1px solid #bebebe;
  align-items: center;
  justify-content: center;
  border-radius: 46px;
  width: 70px;
  margin-right: 8px;

  &.selected {
    color: #000000;
    border: 2px solid #000000;
  }
}
