@import '../../../styles/variables';

.main {
  composes: splitContainer from '../../../styles/splitContainer.module.scss';
  margin-top: $body-top-padding;

  h2 {
    font-family: $font-title;
    margin-top: 0;
    font-size: 4.5rem;
    font-weight: 700;
    margin-bottom: 0.5em;
  }
}

.leftContainer {
  composes: container from '../../../styles/container.module.scss';
  composes: stickyLeftColumn from '../../../styles/splitContainer.module.scss';
}

.sectionHeader {
  font-family: $font-default;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.6em;
}

.cartHeader {
  padding: 0 1em;
}

.cartItemsContainer {
  composes: smFullWidth from '../../../styles/container.module.scss';
  margin-bottom: 1em;

  .cartItems {
    border-radius: 1.5em;
    overflow: hidden;

    @media (max-width: $sm-max-width) {
      border-radius: 0;
    }
  }
}

@media (max-width: $md-max-width) {
  .main {
    composes: mdStacked from '../../../styles/splitContainer.module.scss';
    gap: 3em;
    margin-top: 1.5em;

    h2 {
      font-size: 3rem;
    }
  }
}
