@import '../../../../styles/variables';

.main {
  font-family: $font-default;

  h2 {
    font-family: $font-title;
    margin-top: 19px;
    font-size: 38px;
    font-weight: 600;
    margin-bottom: 0.5em;
  }

  p {
    font-size: 20px;
    font-weight: 300;
  }
}
