@import '../../../.../../../../../styles/variables';

.s3UpgradeSplash {
  text-align: center;
  .avatarContainer {
    position: relative;
  }
  .avatar {
    width: 100%;
    height: 25em;
    object-fit: cover;
    object-position: 20% 0;
    border-radius: 24px;
    margin: 12px 0;
  }

  .text {
    font-size: 1.25em;
    text-align: left;
    position: absolute;
    top: 40px;
    left: 16px;
    .primary {
      color: $type-dark-primary;
    }
    .secondary {
      display: flex;
      width: 55%;
      color: $blue-300;
    }
  }
  .upgrade {
    position: absolute;
    bottom: 50px;
    left: 20px;
    background-color: white;
    font-weight: 500;
    height: 1em;
    width: 35%;
  }
}
