@import '../../../../styles/variables';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  font-family: $font-default;

  .sizeName {
    font-size: 1rem;
    font-weight: 700;
    color: #000;

    @media (min-width: $md-min-width) and (max-width: $md-max-width), (min-width: $xl-min-width) {
      font-size: 1.25rem;
    }
  }

  .sizeDescription {
    font-size: 0.875rem;
    line-height: 1.4em;
    display: flex;
    flex-direction: column;
    color: #737373;
    gap: 0.25em;
    text-align: right;

    @media (min-width: $md-min-width) and (max-width: $md-max-width), (min-width: $xl-min-width) {
      font-size: 1rem;
    }
  }
}

:global(.ecom-flow-revamp) .container .sizeName {
  font-size: 16px;
  line-height: 140%;
  font-weight: 600;
}

:global(.ecom-flow-revamp) .container .sizeDescription {
  font-size: 14px;
  line-height: 140%;
  font-weight: 500;
}
