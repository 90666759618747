@import '../../styles/variables';

.container {
  background-color: #fffa01;
  border-radius: 24px;
  padding: 16px 24px;

  .icon {
    margin-right: 4px;
  }

  .title {
    text-transform: uppercase;
    font-family: $font-title;
    font-size: 16px;
    letter-spacing: -0.02em;
    font-weight: 700;
  }

  .body {
    font-family: $font-default;
    font-size: 12px;
    font-weight: 500;
  }
}
