@import '../../../../styles/variables';

.main {
  font-family: $font-default;

  p {
    font-size: 16px;
    font-weight: 300;
  }

  .transferView {
    display: flex;
    flex-direction: column;

    .buttonPrimary {
      color: #ffffff;
      background-color: #000000;
      padding: 0.45rem 1rem 0.25rem;
      text-align: center;
      text-transform: uppercase;
      min-width: 9.5rem;
      line-height: 1.5;
      margin-top: 1.5rem;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
      vertical-align: middle;
      border-radius: 2px;
      border: 1px solid #000000;
      cursor: pointer;
      font-size: 20px;
      font-weight: 300;
    }

    .textDanger {
      color: $red;
    }
  }

  input[type='email'],
  input[type='password'],
  input[type='tel'],
  input[type='text'] {
    font-family: 'din-2014', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      sans-serif;
    font-weight: 300;
    font-size: 1.25rem; /* 20px is standard for Fi sites */
    font-feature-settings: normal;
    -moz-font-feature-settings: 'kern';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    border: none;
    border-bottom: 1px solid #d8d8d8;
    margin: 0 0 1em 0;
    padding: 0.75em 0;
  }
}
