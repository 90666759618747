@import '../../../../styles/variables';

.sizeVariant {
  composes: variant from '../../../../styles/variants.module.scss';
}

:global(.ecom-flow-revamp) .sizeVariant {
  border-top: 8px #f3f3f2 solid;
  padding: 32px 24px 32px 24px;
}

.variantTitleGroup {
  composes: variantTitleGroup from '../../../../styles/variants.module.scss';
  width: 100%;
}

.variantTitle {
  composes: variantTitle from '../../../../styles/variants.module.scss';
  width: 100%;
}

.variantTitleError {
  composes: variantTitleError from '../../../../styles/variants.module.scss';
}

.moreInfo {
  composes: moreInfo from '../../../../styles/variants.module.scss';
  width: 100%;
}

.makersBandSubtitle {
  margin-top: 8px;
  margin-bottom: 32px;

  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: #737373;
}

button.sizingGuideButton,
button.buyAsAGiftButton {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #f3f3f2;
  padding: 16px 31px 16px 31px;
  border-radius: 1.5em;
  width: 100%;
  margin-top: 13px;

  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  text-decoration: none;
}

.buyAsAGiftContent {
  display: flex;
  align-items: center;
  gap: 8px;
}

button.sizingGuideButton .buttonArrowAngle,
button.buyAsAGiftButton .buttonArrowAngle {
  background-image: url(../../../../../../public/arrow_angle.png);
  background-size: cover;
  height: 20px;
  width: 20px;
}

.nanoSizeDescription {
  font-family: $font-default;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: #737373;
  align-self: stretch;
  margin-top: 6px;
}
