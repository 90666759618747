@import '../../../../styles/variables';

.additionalLink {
  text-align: left;
}

.description {
  font-family: $font-default;
}

.link {
  font-family: $font-default;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.01em;
  margin-top: 16px;
  color: #000000;
}
