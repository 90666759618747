@import '../../styles/variables';

.container {
  font-family: $font-default;
  padding: 0 0 2em;
  color: #000000;
  text-align: center;

  a {
    color: #000000;
    text-decoration: underline;
  }
}
