.checkbox {
  padding-left: 0;
  cursor: pointer;

  .checkboxIcon {
    background: url('../../assets/images/checkbox_unchecked_black.svg');
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: 1em;
    vertical-align: middle;
  }

  input[type='checkbox'] {
    display: none;

    &:disabled + .checkboxIcon {
      background: url('../../assets/images/checkbox_disabled.svg');
    }

    &:checked + .checkboxIcon {
      background: url('../../assets/images/checkbox_checked_black.svg');
    }
  }
}
