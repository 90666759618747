@import '../../../../styles/variables';

.manageActionsContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;

  .loading {
    padding: 0;
    margin: auto;
    height: 30px;
    width: 30px;
    img {
      height: 30px;
      width: 30px;
      width: auto;
    }
  }

  .options {
    margin-top: 0.5em;
    display: flex;
    flex-direction: column;
  }

  .option {
    height: 5em;
    align-items: center;
    display: flex;
    flex-direction: row;
    border: 2px solid var(--Primitives-Gray-gray-100, #e5e5e4);
    border-radius: 16px;
    margin: 5px 0;

    &.shorterOption {
      height: 60px;
    }

    .block {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .text {
      flex-direction: column;
    }

    .title {
      font-weight: 600;
      margin-right: 1em;
    }

    .subtitle {
      margin-top: 3px;
      font-size: 14px;
      color: var(--Type-Light-Secondary, #737373);
    }

    .icon {
      padding: 0 1em;
    }

    .actionArrow * {
      fill: #9e9e9c;
    }
  }

  .hasArrow {
    justify-content: space-between;
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .option {
    width: 48%;
  }
  .full {
    width: 100%;
  }
}
@media (max-width: $xs-max-width) {
  .option {
    .title {
      font-size: 13px;
    }
    .block {
      .subtitle {
        font-size: 12px;
      }
    }
  }
}
@media (max-width: 380px) {
  .option {
    .title {
      font-size: 10px;
    }
    .block {
      .subtitle {
        font-size: 9px;
      }
    }
  }
}
