@import '../../../../styles/variables';

.colorYellow {
  background-color: $collar-yellow;
}

.colorGray {
  background-color: $collar-gray;
}

.colorBlue {
  background-color: $collar-blue;
}

.colorPink {
  background-color: $collar-pink;
}

:global(.ecom-flow-revamp) .colorYellow {
  background: linear-gradient(315deg, #eae101 0%, #fffa01 100%);
}

:global(.ecom-flow-revamp) .colorGray {
  background: linear-gradient(315deg, #555555 0%, #737373 100%);
}

:global(.ecom-flow-revamp) .colorBlue {
  background: linear-gradient(315deg, #0059d5 0%, #0f74ff 100%);
}

:global(.ecom-flow-revamp) .colorPink {
  background: linear-gradient(315deg, #c92781 0%, #e94ca3 100%);
}

.colorStrava {
  background-color: $strava-orange;
}

.scribble {
  background-image: url('../../../../assets/images/icons/color_option_scribble.svg');
  background-size: cover;
}

.strava {
  background-image: url('../../../../assets/images/icons/strava.png');
  background-size: 35%;
  background-repeat: no-repeat;
  background-position: center;
}

.LandsharkSupplyBlackBlue {
  background-image: url(../../../../assets/images/makers-thumbnails/Landshark_Supply_Black_Blue.png);
  background-size: cover;
}

.LandsharkSupplyBlackRed {
  background-image: url(../../../../assets/images/makers-thumbnails/Landshark_Supply_Black_Red.png);
  background-size: cover;
}

.MimiGreenPinkReflective {
  background-image: url(../../../../assets/images/makers-thumbnails/Mimi_Green_Pink_Reflective.png);
  background-size: cover;
}

.MimiGreenRedBiothane {
  background-image: url(../../../../assets/images/makers-thumbnails/Mimi_Green_Red_Biothane.png);
  background-size: cover;
}

.RopeHoundsBubblegumPop {
  background-image: url(../../../../assets/images/makers-thumbnails/Rope_Hounds_Bubblegum_Pop.png);
  background-size: cover;
}

.RopeHoundsMountainTop {
  background-image: url(../../../../assets/images/makers-thumbnails/Rope_Hounds_Mountain_Top.png);
  background-size: cover;
}

.StuntPuppyFishtailTeal {
  background-image: url(../../../../assets/images/makers-thumbnails/Stunt_Puppy_Fishtail_Teal.png);
  background-size: cover;
}

.StuntPuppyFloraFrolic {
  background-image: url(../../../../assets/images/makers-thumbnails/Stunt_Puppy_Flora_Frolic.png);
  background-size: cover;
}

.StuntPuppyGoDog {
  background-image: url(../../../../assets/images/makers-thumbnails/Stunt_Puppy_Go_Dog.png);
  background-size: cover;
}

.StuntPuppyPinesBlue {
  background-image: url(../../../../assets/images/makers-thumbnails/Stunt_Puppy_Pines_Blue.png);
  background-size: cover;
}

.StuntPuppyTealBiothane {
  background-image: url(../../../../assets/images/makers-thumbnails/Stunt_Puppy_Teal_Biothane.png);
  background-size: cover;
}

.ZeeDogPhantom {
  background-image: url(../../../../assets/images/makers-thumbnails/Zee_Dog_Phantom.png);
  background-size: cover;
}
