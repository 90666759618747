@import '../../../../styles/variables';

.container {
  padding: 0;
  font-family: $font-default;
  font-size: 1rem;
  font-weight: 600;

  a {
    text-decoration: underline;
    color: $type-light-primary;
  }
}
