@import '../../styles/variables';

.pill {
  padding: 24px;
  border-radius: 24px;
  margin: 0 27px 0 24px;
}

.pillDefault {
  border: 2px solid black;
}

.pillGray {
  border: none;
  background-color: $bg-light-secondary;
  margin: 0;
}

.pillDowngrade {
  border: 2px solid black;
  margin: 20px 0 20px 0;
}
